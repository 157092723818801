import React, { useState } from "react";
import initialData from "@/helper/initial-data";
import TimelineContext from "./context";

// Context Provider
const TimelineProvider = ({ children }:{
    children: any;
}) => {
    const [items, setItems] = useState(initialData().items);

    // Value object containing state and actions
    const value = {
        items,
        setItems,
    };

    return (
        <TimelineContext.Provider value={value}>
            {children}
        </TimelineContext.Provider>
    );
};

export default TimelineProvider;
