/* eslint-disable unicorn/filename-case */
import moment from "moment";

export default function () {
    const defaultTimeStart = moment().startOf("day");

    return {
        groups: [
            { id: "0", title: "Total engineers" },
            { id: "1", title: "Machine 1" },
            { id: "2", title: "Machine 2" },
            { id: "3", title: "Machine 3" },
        ],
        items: [
            {
                id: "a",
                group: "1",
                title: "All Modules Algebra",
                start: defaultTimeStart.valueOf(),
                end: moment(defaultTimeStart).add(12, "hours").valueOf(),
                itemProps: { "data-engineers": 2 },
                className: "machine_1 ",
            },
            {
                id: "b",
                group: "2",
                title: "Revision Arithmatic-I",
                start: moment(defaultTimeStart).add(1, "day").valueOf(),
                end: moment(defaultTimeStart).add(2, "day").valueOf(),
                itemProps: { "data-engineers": 3 },
                className: "machine_2",
            },
            {
                id: "c",
                group: "3",
                title: "Revision Algebra",
                start: moment(defaultTimeStart).add(2, "day").valueOf(),
                end: moment(defaultTimeStart)
                    .add(3, "day")
                    .add(12, "hour")
                    .valueOf(),
                itemProps: { "data-engineers": 2 },
                className: "machine_3",
            },
            {
                id: "d",
                group: "1",
                title: "3 Modules- VA",
                start: moment(defaultTimeStart).add(3, "day").valueOf(),
                end: moment(defaultTimeStart).add(5, "day").valueOf(),
                itemProps: { "data-engineers": 5 },
                className: "machine_4",
            },
            {
                id: "e",
                group: "2",
                title: "Revision Arithmatic-I",
                start: moment(defaultTimeStart).add(4, "day").valueOf(),
                end: moment(defaultTimeStart)
                    .add(4, "day")
                    .add(12, "hour")
                    .valueOf(),
                itemProps: { "data-engineers": 4 },
                className: "machine_5",
            },
        ],
    };
}
