const QUIZ_SUPER_DOMAINS = [
    { label: "Quant", slug: "quantitative-ability" },
    { label: "Verbal", slug: "verbal-ability" },
    { label: "DILR", slug: "di-lr" },
];

const COMPETE_SUPER_QUIZ_DOMAINS = [
    { label: "Mock CATs", slug: "full-length" },
    { label: "Sectionals", slug: "sectional-tests" },
    { label: "OMETs", slug: "topic-tests" },
];


const PRACTICE_SUPER_DOMAINS = [
    { label: "Quant", slug: "quantitative-ability" },
    { label: "Verbal", slug: "verbal-ability" },
    { label: "DILR", slug: "di-lr" },
    {label:"Speed Concept",slug:"speed-concept"},
];



export { COMPETE_SUPER_QUIZ_DOMAINS,PRACTICE_SUPER_DOMAINS };
export default QUIZ_SUPER_DOMAINS;
