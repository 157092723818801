/* eslint-disable max-lines */
const removeMultipleSlashes = (path: string) => {
    return path.replace(/\/{2,}$/, "/");
};

export default {
    // Auth
    login: () => ({
        href: "/login",
        as: "/login",
    }),
    signUp: () => ({
        href: "/signup",
        as: "/signup",
    }),
    admin: () => ({
        href: "/admin",
        as: "/admin",
    }),
    auth: () => ({
        href: "/auth",
        as: "/auth",
    }),
    channel: () => `/channel`,
    verifyEmail: () => ({
        href: "/auth/verify-email",
        as: "/auth/verify-email",
    }),

    verifyMobile: () => ({
        href: "/auth/verify-mobile",
        as: "/auth/verify-mobile",
    }),

    // General
    home: () => ({
        href: "/",
        as: "/",
    }),
    profileData: () => ({
        href: "/profile-data",
        as: "/profile-data",
    }),
    notFound: () => ({
        href: "/404",
        as: "/404",
    }),
    learn: ({
        isFree = false,
        category = "",
        topic = "",
        superDomain = "",
    }: {
        isFree: boolean;
        category?: string;
        topic?: string;
        superDomain?: string;
    }) => ({
        href: removeMultipleSlashes(
            `${isFree ? "/free" : ""}/learn/${superDomain}/${category}/${topic}`
        ),
        as: removeMultipleSlashes(
            `${isFree ? "/free" : ""}/learn/${superDomain}/${category}/${topic}`
        ),
    }),
    competeHome: ({ isFree = false }: { isFree: boolean }) => ({
        href: removeMultipleSlashes(
            `${isFree ? "/free" : ""}/compete/full-length`
        ),
        as: removeMultipleSlashes(
            `${isFree ? "/free" : ""}/compete/full-length`
        ),
    }),
    compete: ({
        isFree = false,
        domain = "",
        slug = "",
        question_position = "",
    }: {
        isFree: boolean;
        domain: string;
        slug?: string;
        question_position?: string;
    }) => ({
        href: removeMultipleSlashes(
            `${
                isFree ? "/free" : ""
            }/compete/${domain}/${slug}/${question_position}`
        ),
        as: removeMultipleSlashes(
            `${
                isFree ? "/free" : ""
            }/compete/${domain}/${slug}/${question_position}`
        ),
    }),

    competeInstructions: ({
        slug = "",
        isFree = false,
    }: {
        slug?: string;
        isFree: boolean;
    }) => ({
        href: removeMultipleSlashes(`/compete/quiz/${slug}/instructions`),
        as: removeMultipleSlashes(`/compete/quiz/${slug}/instructions`),
    }),
    practice: ({
        isFree = false,
        super_domain,
        slug = "",
        question_position,
    }: {
        isFree: boolean;
        super_domain: string;
        slug?: string;
        question_position?: number;
    }) => ({
        href: removeMultipleSlashes(
            `${isFree ? "/free" : ""}/practice/${super_domain}/${slug}/${
                question_position ?? ""
            }`
        ),
        as: removeMultipleSlashes(
            `${isFree ? "/free" : ""}/practice/${super_domain}/${slug}/${
                question_position ?? ""
            }`
        ),
    }),
    plan: () => ({
        href: "/plans",
        as: "/plans",
    }),
    plans: ({
        planId,
        group = "",
    }: {
        planId?: string;
        group?: number | string;
    }) => ({
        href: `/plans/pay/${planId}/${group}`,
        as: `/plans/pay/${planId}/${group}`,
    }),
    adminQuizEdit: ({ id }: { id: number }) => ({
        href: `/admin/quiz/edit/${id}`,
        as: `/admin/quiz/edit/${id}`,
    }),
    adminQuestionEdit: ({ id }: { id: number }) => ({
        href: `/admin/question/edit/${id}`,
        as: `/admin/question/edit/${id}`,
    }),

    infozone: () => `/blogs`,

    infozoneAnnouncements: () => ({
        href: `/blogs/announcements/`,
        as: `/blogs/announcements/`,
    }),

    infozoneAnnouncement: ({ slug }: { slug: string }) => ({
        href: `/blogs/announcements/${slug}`,
        as: `/blogs/announcements/${slug}`,
    }),

    infozoneCollege: ({ slug }: { slug: string }) => `/blogs/colleges/${slug}`,

    infozoneExam: ({ slug }: { slug: string }) => `/blogs/exam/${slug}`,

    competeQuizTest: ({
        slug,
        isFree = false,
    }: {
        slug: string;
        isFree: boolean;
    }) => `${isFree ? "/free" : ""}/compete/quiz/${slug}/take`,

    speedPracticeResult: ({
        isFree = false,
        slug,
        summary_id,
    }: {
        isFree: boolean;
        slug: string;
        summary_id: string;
    }) =>
        `${isFree ? "/free" : ""}/speed/${slug}/practice/result/${summary_id}`,

    speedChallengeResult: ({
        isFree = false,
        slug,
        summary_id,
    }: {
        isFree: boolean;
        slug: string;
        summary_id: string;
    }) =>
        `${isFree ? "/free" : ""}/speed/${slug}/challenge/result/${summary_id}`,

    // function to accept

    speedPracticeTest: ({
        isFree = false,
        slug,
        summaryID,
        questionID,
    }: {
        isFree: boolean;
        slug: string;
        summaryID: string;
        questionID: string;
    }) =>
        `${
            isFree ? "/free" : ""
        }/speed/${slug}/practice/test/${summaryID}/${questionID}`,

    speedChallengeTest: ({
        isFree = false,
        slug,
        summaryID,
        questionID,
    }: {
        isFree: boolean;
        slug: string;
        summaryID: string;
        questionID: string;
    }) =>
        `${
            isFree ? "/free" : ""
        }/speed/${slug}/challenge/test/${summaryID}/${questionID}`,

    speedQuantTest: ({
        isFree = false,
        summaryID,
        questionIndex,
    }: {
        isFree?: boolean;
        summaryID: string;
        questionIndex: number;
    }) =>
        `${
            isFree ? "/free" : ""
        }/speed/quant/test/${summaryID}/${questionIndex}`,

    speedQuantResult: ({
        isFree = false,
        summaryID,
    }: {
        summaryID: string;
        isFree?: boolean;
    }) => `${isFree ? "/free" : ""}/speed/quant/result/${summaryID}`,

    speedVerbalTest: ({
        isFree = false,
        summaryID,
        questionIndex,
    }: {
        isFree: boolean;
        summaryID: string;
        questionIndex: number;
    }) =>
        `${
            isFree ? "/free" : ""
        }/speed/speed-verbal/test/${summaryID}/${questionIndex}`,

    speedVerbalResult: ({
        summaryID,
        isFree = false,
    }: {
        summaryID: string;
        isFree: boolean;
    }) => `${isFree ? "/free" : ""}/speed/speed-verbal/result/${summaryID}`,

    dailyQuizInstructions: ({
        isFree = false,
        slug,
    }: {
        isFree: boolean;
        slug: string;
    }) => `${isFree ? "/free" : ""}/daily/daily-quiz/${slug}/instructions`,

    dailyTest: ({
        isFree = false,
        userSummaryID,
        questionPosition,
    }: {
        isFree: boolean;
        userSummaryID: string;
        questionPosition: number;
    }) =>
        `${
            isFree ? "/free" : ""
        }/daily/daily-quiz/test/${userSummaryID}/${questionPosition}`,

    dailyQuizResult: ({
        userSummaryID,
        isFree = false,
    }: {
        userSummaryID: string;
        isFree: boolean;
    }) => `${isFree ? "/free" : ""}/daily/daily-quiz/result/${userSummaryID}`,

    speedConceptTest: ({
        isFree = false,
        category,
        summaryID,
    }: {
        isFree: boolean;
        category: string;
        summaryID: string;
    }) =>
        `${
            isFree ? "/free" : ""
        }/speed/speed-concept/${category}/test/${summaryID}`,

    speedConceptResult: ({
        isFree = false,
        category,
        summaryID,
    }: {
        isFree: boolean;
        category: string;
        summaryID: string;
    }) =>
        `${
            isFree ? "/free" : ""
        }/speed/speed-concept/${category}/result/${summaryID}`,

    dashboard: ({ tab }: { tab: string }) => `/dashboard/${tab}`,

    practiceResult: ({
        summaryID,
        isFree = false,
    }: {
        summaryID: string;
        isFree: boolean;
    }) => `${isFree ? "/free" : ""}/practice/result/${summaryID}`,

    speedQuant: ({ isFree = false }: { isFree?: boolean }) =>
        `${isFree ? "/free" : ""}/speed/quant`,

    speedMath: ({ isFree = false }: { isFree?: boolean }) =>
        `${isFree ? "/free" : ""}/speed/speed-math`,
    speedMathPractice: ({ isFree = false }: { isFree?: boolean }) =>
        `${isFree ? "/free" : ""}/speed/speed-math/practice`,
    speedMathChallenge: ({ isFree = false }: { isFree?: boolean }) =>
        `${isFree ? "/free" : ""}/speed/speed-math/challenge`,

    speedVerbal: ({ isFree = false }: { isFree?: boolean }) =>
        `${isFree ? "/free" : ""}/speed/speed-verbal`,

    speedConceptCategory: ({
        category,
        isFree = false,
    }: {
        category: string;
        isFree?: boolean;
    }) => `${isFree ? "/free" : ""}/speed/speed-concept/${category}`,

    speedConcept: ({ isFree = false }: { isFree: boolean }) =>
        `${isFree ? "/free" : ""}/speed/speed-concept`,

    competeResult: ({
        summaryID,
        isFree = false,
    }: {
        summaryID: string;
        isFree: boolean;
    }) => `${isFree ? "/free" : ""}/compete/result/${summaryID}`,

    catShowMarks: () => `/cat-show-marks`,
    catResult: (slug: string) => `/cat-result/${slug}`,
    catPreparation: () => `/info/cat-preparation`,
    catPreparation2: () => `/info/cat-preparation-2`,
    channelRoute: ({ category, slug }: { category: string; slug: string }) =>
        `/channel/${category}/${slug}`,
};
