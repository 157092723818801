const levelsData = [
    {
        rank: "Newbie",
        level: "1 to 2",
        reward: "-",
    },
    {
        rank: "Rookie",
        level: "3 to 5",
        reward: "Formula Booklet",
        leftText: "CAT ",
    },
    {
        rank: "Explorer",
        level: "6 to 10",
        reward: "Mockat Study Group ",
        leftText: "Join ",
        rightText: "on Telegram",
    },
    {
        rank: "Enthusiast",
        level: "11 to 17",
        reward: "DILR Case-making Session ",
        rightText: "to crack DILR",
    },
    {
        rank: "Specialist",
        level: "18 to 25",
        reward: "Mental Quant Session ",
        rightText: "for Speed and Accuracy",
    },
    {
        rank: "Expert",
        level: "26 to 32",
        reward: "Interview Workshops",
        leftText: "Access to Form-filling & ",
    },
    {
        rank: "Achiever",
        level: "33 to 40",
        reward: "3-day workshop ",
        rightText: "on 99.9+%iler's Strategies & Tips",
    },
    {
        rank: "Master",
        level: "41 to 45",
        reward: "100%iler Mock ",
        leftText: "Three ",
        rightText: "Tests & Review Sessions",
    },
    {
        rank: "Champion",
        level: "46 to 50",
        reward: "WAT-PI Mentor",
        leftText: "IIM Student/Grad as ",
    },
    {
        rank: "Wizard",
        level: "51 onwards",
        reward: "Mockat Wizards ",
        leftText: "Membership at ",
        rigthText: "Group",
    },
];

const getBadgeInfo = (totalXP: number) => {
    const level = Math.floor(totalXP / 200) + 1;

    const currentXp = Math.floor(totalXP % 200);

    const filteredLevel = levelsData?.find((data) => {
        if (data.level.includes("onwards")) {
            return level >= Number.parseInt(data.level.split(" ")[0]);
        } else {
            const [minLevel, maxLevel] = data.level.split(" to ");

            return (
                level >= Number.parseInt(minLevel) &&
                level <= Number.parseInt(maxLevel)
            );
        }
    });

    const badge = filteredLevel?.rank ?? "";

    let numberOfItemsUnlocked = 0;

    for (const data of levelsData) {
        if (data.reward !== "-") {
            if (data.level.includes("onwards")) {
                if (level >= Number.parseInt(data.level.split(" ")[0]))
                    numberOfItemsUnlocked++;
            } else {
                if (Number.parseInt(data.level.split(" to ")[0]) <= level) {
                    numberOfItemsUnlocked++;
                }
            }
        }
    }

    return {
        level,
        badge,
        currentXp,
        numberOfItemsUnlocked,
        totalNumberOfItems:
            levelsData.filter((data) => data.reward !== "-")?.length ?? 0,
    };
};

export default getBadgeInfo;
