const SPEED_MATH_CHALLENGE_TIMER = [
    {
        value: 60,
        label: "1 min",
    },
    {
        value: 120,
        label: "2 min",
    },
];

const SPEED_MATH_PRACTICE_TIMER = [
    {
        value: 60,
        label: "1 min",
    },
    {
        value: 120,
        label: "2 min",
    },
    {
        value: 300,
        label: "5 min",
    },
];

const SPEED_QUANT_TIMER = [
    {
        value: 60,
        label: "1 min",
    },
    {
        value: 120,
        label: "2 min",
    },
    {
        value: 300,
        label: "5 min",
    },
];

const SPEED_VERBAL_TIMER = [
    {
        value: 60,
        label: "1 min",
    },
    {
        value: 120,
        label: "2 min",
    },
    {
        value: 300,
        label: "5 min",
    },
];

const SPEED_VOCAB_TIMER = [
    {
        value: 60,
        label: "1 min",
    },
    {
        value: 120,
        label: "2 min",
    },
    {
        value: 300,
        label: "5 min",
    },
];

export {
    SPEED_MATH_CHALLENGE_TIMER,
    SPEED_QUANT_TIMER,
    SPEED_VERBAL_TIMER,
    SPEED_VOCAB_TIMER,
    SPEED_MATH_PRACTICE_TIMER,
};
