import routes from "routes";


const UNPROTECTED_ROUTES = new Set([
    routes.login().href,
    routes.signUp().href,
    routes.auth().href,
    routes.home().href,
    routes.verifyEmail().href,
    routes.verifyMobile().href,
    routes.profileData().href,
    routes.notFound().href,
    routes.catResult("marks"),
    routes.catResult("response-sheet"),
    routes.catPreparation(),
    routes.catPreparation2(),
    routes.channel(),
    "/webex"
]);

export default UNPROTECTED_ROUTES;