import isProduction from "./isProduction";


const QUIZZES = {
    SPEED_ALL_QUANT: isProduction ? 968 : 787,
    SPEED_QUANT_ARITHMETIC: isProduction ? 1165 : 2740,
    SPEED_VERBAL_VOCAB: isProduction ? 969 : 788,
    SPEED_VERBAL_GRAMMAR: isProduction ? 1176 : 2741,
};

export default QUIZZES;