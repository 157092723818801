import { toast } from "react-toastify";
import { useRouter } from "next/router";
import { useGoogleLoginLazyQuery } from "../operations/operations.generated";

const useGoogleLogin = () => {
    const router = useRouter();

    const [googleLogin] = useGoogleLoginLazyQuery({
        onCompleted(data) {
            if (data?.googleLogin?.success) {
                router.push(data?.googleLogin?.message ?? "/");
            } else {
                toast.dismiss();
                toast.error(
                    data?.googleLogin?.message ?? "Oops! Something went wrong."
                );
            }
        },
    });

    const handleGoogleSignIn = () => {
        googleLogin({});
    };

    return {
        handleGoogleSignIn,
    };
};

export default useGoogleLogin;
