const getSecondsFromInterval = (interval: string): number => {
    if(!interval) return 0;
    
    const [hours, minutes, seconds] = interval.split(":");

    return (
        Number.parseInt(hours) * 3600 +
        Number.parseInt(minutes) * 60 +
        Number.parseInt(seconds)
    );
};

export default getSecondsFromInterval;
