import { useContext } from "react";
import PackageSubscriptionContext from "./context";

const useCurrentPlan = () => {
    const { packageSubscription } = useContext(PackageSubscriptionContext);

    return packageSubscription;
};

export default useCurrentPlan;
