import React from "react";
import { ArrowLeft } from "lucide-react";
import Link from "next/link";


const AccessDenied = () => {
    return (
        <div className="flex h-screen w-screen items-center justify-center text-center">
            <Link
                href={"/"}
                className="absolute left-16 top-10 flex justify-center text-primary-1 underline"
            >
                <ArrowLeft className="w-4" /> Back to home
            </Link>
            <div className="">
                <p className="mb-4 text-4xl font-bold text-gray-500">
                    Access Denied
                </p>
                <p className="mb-2 text-gray-400">
                    You do not have permission to view this page.
                </p>
                <p className="mb-2 text-gray-400">
                    Please check your credentials and try again.
                </p>
            </div>
        </div>
    );
};

export default AccessDenied;