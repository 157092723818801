/* eslint-disable max-lines */
import { getImageUrl } from "@/utils";
import routes from "routes";

const SIDE_NAV_OPTIONS = [
    // {
    //     name: "Free Stuff",
    //     link: "/free",
    //     icon: getImageUrl("/images/question.svg"),
    //     imageWidth: 15,
    // },
    {
        name: "Plans",
        link: "/plans",
        icon: getImageUrl("/images/sidebarIcons/cart.svg"),
        imageWidth: 30,
    },
    {
        name: "Dashboard",
        link: "/dashboard/snapshot",
        icon: getImageUrl("/images/dashboard.svg"),
        imageWidth: 17,
        subMenu: [
            {
                name: "Snapshot",
                link: "/dashboard/snapshot",
                icon: "",
            },
            {
                name: "Planner",
                link: "/dashboard/planner",
                icon: "",
            },
            {
                name: "Activity",
                link: "/dashboard/activity",
                icon: "",
            },
        ],
    },
    {
        name: "Speed",
        link: "/",
        icon: getImageUrl("/images/speed.svg"),
        subMenu: [
            {
                name: "Speed Math",
                link: "/speed/speed-math",
                icon: getImageUrl("/images/sidebarIcons/speed-math.svg"),
            },
            {
                name: "Speed Quant",
                link: "/speed/quant",
                icon: getImageUrl("/images/sidebarIcons/quant.svg"),
            },
            {
                name: "Speed Verbal",
                link: "/speed/speed-verbal",
                icon: getImageUrl("/images/sidebarIcons/speed-verbal.svg"),
            },
            {
                name: "Speed Concepts",
                link: "/speed/speed-concept",
                icon: getImageUrl("/images/sidebarIcons/speed-concepts.svg"),
            },
        ],
        imageWidth: 19,
    },
    {
        name: "Daily",
        link: "/",
        icon: getImageUrl("/images/daily.svg"),
        subMenu: [
            {
                name: "Daily Quiz",
                link: "/daily/daily-quiz",
                icon: getImageUrl("/images/sidebarIcons/daily-quiz.svg"),
            },
            {
                name: "Daily PoV",
                link: "/daily/daily-pov",
                icon: getImageUrl("/images/sidebarIcons/daily-pov.svg"),
            },
            {
                name: "Daily News",
                link: "/daily/daily-news",
                icon: getImageUrl("/images/sidebarIcons/daily-news.svg"),
            },
            {
                name: "Bookmarked Questions",
                link: "/daily/daily-quiz/bookmarked-questions",
                icon: getImageUrl("/images/bookmark.svg"),
            },
        ],
        imageWidth: 16.5,
    },
    {
        name: "Learn",
        link: "/learn",
        icon: getImageUrl("/images/learn.svg"),
        subMenu: [
            {
                name: "Quant Lessons",
                link: "/learn/quantitative-ability",
                icon: getImageUrl("/images/sidebarIcons/quant.svg"),
            },
            {
                name: "Verbal Lessons",
                link: "/learn/verbal-ability",
                icon: getImageUrl("/images/sidebarIcons/verbal.svg"),
            },
            {
                name: "DILR Lessons",
                link: "/learn/di-lr",
                icon: getImageUrl("/images/sidebarIcons/dilr.svg"),
            },
            {
                name: "Live Classes",
                link: "/learn/live-classes",
                icon: getImageUrl("/images/calender-fill.svg"),
            },
            {
                name: "Bookmarked Lessons",
                link: "/learn/bookmarked-topics",
                icon: getImageUrl("/images/bookmark.svg"),
            },
        ],
        imageWidth: 17,
    },
    {
        name: "Practice",
        link: "/practice",
        icon: getImageUrl("/images/practice.svg"),
        subMenu: [
            {
                name: "Quant Practice",
                link: routes.practice({
                    super_domain: "quantitative-ability",
                    isFree: false,
                }).href,
                icon: getImageUrl("/images/sidebarIcons/quant.svg"),
            },
            {
                name: "Verbal Practice",
                link: routes.practice({
                    super_domain: "verbal-ability",
                    isFree: false,
                }).href,
                icon: getImageUrl("/images/sidebarIcons/verbal.svg"),
            },
            {
                name: "DILR Practice",
                link: routes.practice({ super_domain: "di-lr", isFree: false })
                    .href,
                icon: getImageUrl("/images/sidebarIcons/dilr.svg"),
            },
            {
                name: "Bookmarked Questions",
                link: "/lesson/bookmarks",
                icon: getImageUrl("/images/bookmark.svg"),
            },
        ],
        imageWidth: 17,
    },
    {
        name: "Compete",
        link: "/compete",
        icon: getImageUrl("/images/complete.svg"),
        subMenu: [
            {
                name: "Mock CATs",
                link: "/compete/full-length",
                icon: getImageUrl("/images/sidebarIcons/mock-cats.svg"),
            },
            {
                name: "Sectionals",
                link: "/compete/sectional-tests",
                icon: getImageUrl("/images/sidebarIcons/sectionals.svg"),
            },
            {
                name: "OMETs",
                link: "/compete/topic-tests",
                icon: getImageUrl("/images/sidebarIcons/omets.svg"),
            },
            {
                name: "Bookmarked Questions",
                link: "/lesson/bookmarks",
                icon: getImageUrl("/images/bookmark.svg"),
            },
        ],
        imageWidth: 18,
    },
    {
        name: "Blogs",
        link: routes.infozone(),
        icon: getImageUrl("/images/info.svg"),
        imageWidth: 20,
    },
    {
        name: "Free Stuff",
        link: "/free",
        icon: getImageUrl("/images/question.svg"),
        imageWidth: 15,
    },
];

export default SIDE_NAV_OPTIONS;
