import DOMPurify from "isomorphic-dompurify";
// @ts-ignore
import katex from "katex";
import "katex/dist/katex.min.css";

const getSanitizedHtml = (
    content: string | null | undefined,
    sanitize = true
) => {
    if (!content || !content.split) return "";

    const contentSplitAt$ = content?.split("$");

    const sanitizedString = contentSplitAt$
        ?.map((item: string, index: number) =>
            mapItemToString(item, index, contentSplitAt$.length)
        )
        .join("");

    return sanitize ? DOMPurify.sanitize(sanitizedString) : sanitizedString;
};

const mapItemToString = (item: string, index: number, length: number) => {
    const isIndexOdd = index % 2 === 1;

    const isLastItem = index === length - 1;

    let result = item;

    if (isIndexOdd && !isLastItem) {
        const doesItemContainNbsp = result.includes("&nbsp");

        if (doesItemContainNbsp)
            result = result.replaceAll("&nbsp;", "").replaceAll("&nbsp", "");

        try {
            return katex.renderToString(result);
        } catch {
            return `<span key=${index}>${item}</span>`;
        }
    } else {
        return `<span key=${index}>${item}</span>`;
    }
};

export default getSanitizedHtml;
