/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/template-indent */
/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import * as Types from '../../../types/generated-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/utils/apolloReactHooks';
const defaultOptions = {} as const;
export type GoogleLoginQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GoogleLoginQuery = { __typename?: 'Query', googleLogin: { __typename?: 'GenericResponse', success: boolean, message?: string | null } };


export const GoogleLoginDocument = gql`
    query googleLogin {
  googleLogin {
    success
    message
  }
}
    `;

/**
 * __useGoogleLoginQuery__
 *
 * To run a query within a React component, call `useGoogleLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoogleLoginQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GoogleLoginQuery, GoogleLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GoogleLoginQuery, GoogleLoginQueryVariables>(GoogleLoginDocument, options);
      }
export function useGoogleLoginLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GoogleLoginQuery, GoogleLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GoogleLoginQuery, GoogleLoginQueryVariables>(GoogleLoginDocument, options);
        }
export type GoogleLoginQueryHookResult = ReturnType<typeof useGoogleLoginQuery>;
export type GoogleLoginLazyQueryHookResult = ReturnType<typeof useGoogleLoginLazyQuery>;
export type GoogleLoginQueryResult = Apollo.QueryResult<GoogleLoginQuery, GoogleLoginQueryVariables>;