const getElapsedTimeFromInterval = (interval: string) => {
    const [hours, minutes, seconds] = interval.split(":");

    return {
        minutes: Number(minutes),
        seconds: Number(seconds),
        hours: Number(hours),
    };
};

export default getElapsedTimeFromInterval;
