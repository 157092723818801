import React, { useState } from "react";
import ResultContext from "./context";

const ResultProvider = ({ children }: { children: JSX.Element }) => {
    const [resultData, setResultData] = useState({});

    return (
        <ResultContext.Provider
            value={{
                resultData,
                setResultData,
            }}
        >
            {children}
        </ResultContext.Provider>
    );
};

export default ResultProvider;
