import { getImageUrl } from "@/utils";

const SPEED_CONCEPT_CATEGORIES = [
    {
        name: "Numbers",
        slug: "numbers-3",
        id: 148,
        description: "Need to be Odd <br /> to be Number 1",
        icon: getImageUrl("/images/numbers-icon.svg"),
    },
    {
        name: "Arithmetic I",
        slug: "arithmetic-i-3",
        id: 149,
        description: "Fundamental Maths <br /> for any Manager",
        icon: getImageUrl("/images/arithmetic-1-icon.svg"),
    },
    {
        name: "Arithmetic II",
        slug: "arithmetic-ii-2",
        id: 150,
        description: "Time & Speed not <br /> just in the tests",
        icon: getImageUrl("/images/arithmetic-ii-icon.svg"),
    },
    {
        name: "Algebra",
        slug: "algebra-2",
        id: 151,
        description: "Stable Mathematics <br /> despite Variables",
        icon: getImageUrl("/images/algebra-icon.svg"),
    },
    {
        name: "Geometry",
        slug: "geometry-3",
        id: 152,
        description: "The Poetry of <br /> Mathematics",
        icon: getImageUrl("/images/geometry-icon.svg"),
    },
    {
        name: "Modern Maths",
        slug: "modern-maths-3",
        id: 153,
        description: "Predict Outcomes <br /> & Observe Patterns",
        icon: getImageUrl("/images/mordern-math-icon.svg"),
    },
];

export default SPEED_CONCEPT_CATEGORIES;
