// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shuffleArray = (arr: any[]) => {
    const newArr = [...arr];

    for (let i = newArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));

        [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
    }

    return newArr;
};

export default shuffleArray;
