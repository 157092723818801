import React, { Fragment, useContext } from "react";
import ModalsContext from "@/context/modals";
import { Dialog, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import Spinner from "../Spinner";
import Form from "./Form";
import { useRegisterUser } from "./hooks";


type SelectionProp = {
    firstName: string;
    lastName: string;
    email: string;
    otp: string;
    mobile: string;
    username: string;
    password: string;
    state: string;
    city: string;
    confirmPassword: string;
    emailVerificationSent: boolean;
    mobileVerificationSent: boolean;
    emailVerified: boolean;
    mobileVerified: boolean;
};

type ErrorProp = {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    username: string;
    password: string;
    state: string;
    city: string;
    confirmPassword: string;
};

const RegisterModal = () => {
    const { query } = useRouter();

    const popup = query?.popup;

    const isAskingForRegister = popup === "register";

    const closeModal = () => {
        setRegisterOpen(false);
    };

    const { setRegisterOpen, setLoginOpen } = useContext(ModalsContext);

    const { errors, selection, handleSubmit, setSelection, loading } =
        useRegisterUser();

    return (
        <div>
            <Transition appear show={true} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded bg-white px-6 py-4 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="mb-4  w-full text-center text-2xl font-bold  text-blueGray-700"
                                    >
                                        Register
                                    </Dialog.Title>
                                    {isAskingForRegister && (
                                        <div className="my-3 flex items-center justify-center">
                                            <p className="font-base text-lg text-orange">
                                                Please register to continue
                                            </p>
                                        </div>
                                    )}
                                    <Form
                                        selection={selection}
                                        setSelection={setSelection}
                                        errors={errors}
                                    />

                                    <button
                                        onClick={handleSubmit}
                                        className="mt-2  w-full rounded-lg bg-blue-3 bg-opacity-90 px-12 py-2 text-lg font-medium text-white outline-none"
                                    >
                                        {loading ? <Spinner /> : "Register"}
                                    </button>
                                    <div
                                        className="mt-6 flex items-center justify-center pb-3"
                                        onClick={() => {
                                            setRegisterOpen(false);
                                            setLoginOpen(true);
                                        }}
                                    >
                                        <p className="font-medium text-blue-3">
                                            Already have an account?
                                        </p>
                                        <p className="ml-2 cursor-pointer text-link">
                                            Login
                                        </p>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export type { ErrorProp, SelectionProp };

export default RegisterModal;