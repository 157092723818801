import { renderToString } from "react-dom/server";
import { MockedFunction } from "vitest";

type Props = {
    spy: MockedFunction<
        ({ children }: { children: React.ReactNode }) => JSX.Element
    >;
    altTag: string;
};

const expectImageAltTagInChildrenPropOfSpy = ({ spy, altTag }: Props) => {
    const childrenProp = spy?.mock?.lastCall?.[0].children;

    // @ts-ignore
    const childrenRenderedToString = renderToString(childrenProp);

    // @ts-ignore
    expect(childrenRenderedToString.includes(`alt="${altTag}"`)).toBe(true);
};

export default expectImageAltTagInChildrenPropOfSpy;
