const getIntervalFromElapsedTime = ({
    hours,
    minutes,
    seconds,
}: {
    hours?: number;
    minutes?: number;
    seconds?: number;
}) => {
    // reutrn HH:MM:SS with leading zeros
    const hoursString = hours ? hours.toString().padStart(2, "0") : "00";

    const minutesString = minutes ? minutes.toString().padStart(2, "0") : "00";

    const secondsString = seconds ? seconds.toString().padStart(2, "0") : "00";

    // if hour is 0, then return MM:SS with leading zeros

    if (hoursString === "00") {
        return `${minutesString}:${secondsString}`;
    }

    return `${hoursString}:${minutesString}:${secondsString}`;
};

export default getIntervalFromElapsedTime;
