const QUIZ_DOMAINS = [
    { label: "Quant", slug: "quantitative-ability" },
    { label: "Verbal", slug: "verbal-ability" },
    { label: "DILR", slug: "di-lr" },
];

const LEARN_DOMAINS = [
    { label: "Quant", slug: "quantitative-ability" },
    { label: "Verbal", slug: "verbal-ability" },
    { label: "DILR", slug: "di-lr" },
    { label: "Live Classes", slug: "live-classes" },
];


const COMPETE_QUIZ_DOMAINS = [
    { label: "Mock CATs", slug: "full-length" },
    { label: "Sectionals", slug: "sectional-tests" },
    { label: "OMETs", slug: "topic-tests" },
];

export { COMPETE_QUIZ_DOMAINS,LEARN_DOMAINS };
export default QUIZ_DOMAINS