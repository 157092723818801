import React, { useState } from "react";
import SpeedContext from "./context";

const SpeedProvider = ({ children }: { children: JSX.Element }) => {
    const [speedData, setSpeedData] = useState({});

    return (
        <SpeedContext.Provider
            value={{
                speedData,
                setSpeedData,
            }}
        >
            {children}
        </SpeedContext.Provider>
    );
};

export default SpeedProvider;
