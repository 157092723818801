/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import isClient from "./isClient";

type Props = {
    videoContainerID: string;
    otp: string;
    playbackInfo: string;
    hasAccess?: boolean;
};

const renderVideoFromVideoCipher = ({
    videoContainerID,
    otp,
    playbackInfo,
    hasAccess= true,
}: Props) => {
    (function (v, i, d, e, o) {
        let a, m;

        v[o] = v[o] || {};
        v[o].add =
            v[o].add ||
            function V(a) {
                (v[o].d = v[o].d || []).push(a);
            };

        if (!v[o].l) {
            v[o].l = 1 * Date.now();
            // @ts-ignore
            // eslint-disable-next-line unicorn/prefer-query-selector
            (a = i.createElement(d)), (m = i.getElementsByTagName(d)[0]);
            a.async = 1;
            a.src = e;
            m.parentNode.insertBefore(a, m);
        }
    })(
        window,
        document,
        "script",
        "https://player.vdocipher.com/playerAssets/1.x/vdo.js",
        "vdo"
    );

    
    vdo?.add({
        otp,
        playbackInfo,
        theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
        container: isClient() && document.querySelector(`#${videoContainerID}`),
        controls: false,
        plugins: [
            {
                name: "keyboard",
                options: {
                    bindings: {
                        Space: (player) =>
                            player.status === 1
                                ? player.pause()
                                : player.play(),
                        Up: (player) => player.setVolume(player.volume + 0.2),
                        Down: (player) => player.setVolume(player.volume - 0.2),
                        Left: (player) => player.seek(player.currentTime - 5),
                        Right: (player) => player.seek(player.currentTime + 5),
                    },
                },
            },
        ],
    });
};

export default renderVideoFromVideoCipher;