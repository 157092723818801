/* eslint-disable max-lines */
import React from "react";
import STATE_OPTIONS from "@/constants/stateOptions";
import { ErrorProp, SelectionProp } from "./RegisterModal";

type Props = {
    selection: SelectionProp;
    setSelection: React.Dispatch<React.SetStateAction<SelectionProp>>;
    errors: ErrorProp;
};

const Form = ({ selection, setSelection, errors }: Props) => {
    return (
        <div className="flex justify-between gap-4">
            <div className="flex flex-col">
                <p className="my-2 text-sm font-medium text-blueGray-700">
                    First Name*
                </p>
                <input
                    type="text"
                    required
                    name="firstName"
                    value={selection.firstName}
                    onKeyDown={(e) => {
                        if (e.code === "Space") {
                            e.preventDefault();
                        }
                    }}
                    tabIndex={1}
                    onChange={(e) =>
                        setSelection({
                            ...selection,
                            firstName: e.target.value,
                        })
                    }
                    placeholder=""
                    className="mb-2 w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                />
                {errors.firstName !== "" && (
                    <p className="text-sm text-red-500">{errors.firstName}</p>
                )}
                <p className="my-2 text-sm font-medium text-blueGray-700">
                    Email*
                </p>
                <input
                    name="email"
                    type="email"
                    required
                    tabIndex={2}
                    value={selection.email}
                    onKeyDown={(e) => {
                        if (e.code === "Space") {
                            e.preventDefault();
                        }
                    }}
                    onChange={(e) =>
                        setSelection({
                            ...selection,
                            email: e.target.value,
                        })
                    }
                    placeholder=""
                    className="mb-2 w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                />
                {errors.email !== "" && (
                    <p className="text-sm text-red-500">{errors.email}</p>
                )}
                <p className="my-2 text-sm font-medium text-blueGray-700">
                    Username*
                </p>
                <input
                    required
                    type="text"
                    onKeyDown={(e) => {
                        if (e.code === "Space") {
                            e.preventDefault();
                        }
                    }}
                    tabIndex={4}
                    name="username"
                    value={selection.username}
                    onChange={(e) =>
                        setSelection({
                            ...selection,
                            username: e.target.value,
                        })
                    }
                    placeholder=""
                    className="mb-2 w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                />
                {errors.username !== "" && (
                    <p className="text-sm text-red-500">{errors.username}</p>
                )}
                <p className="mt-22 my-2 text-sm font-medium text-blueGray-700">
                    Confirm Password*
                </p>
                <input
                    required
                    type="password"
                    onKeyDown={(e) => {
                        if (e.code === "Space") {
                            e.preventDefault();
                        }
                    }}
                    name="confirmPassword"
                    value={selection.confirmPassword}
                    onChange={(e) =>
                        setSelection({
                            ...selection,
                            confirmPassword: e.target.value,
                        })
                    }
                    placeholder=""
                    tabIndex={6}
                    className="mb-2 w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                />
                {errors.confirmPassword !== "" && (
                    <p className="text-sm text-red-500">
                        {errors.confirmPassword}
                    </p>
                )}
                <p className="my-2 text-sm font-medium text-blueGray-700">
                    City*
                </p>
                <input
                    name="city"
                    type="text"
                    required
                    tabIndex={8}
                    onKeyDown={(e) => {
                        if (e.code === "Space") {
                            e.preventDefault();
                        }
                    }}
                    value={selection.city}
                    onChange={(e) =>
                        setSelection({
                            ...selection,
                            city: e.target.value,
                        })
                    }
                    placeholder=""
                    className="mb-2 w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                />
                {errors.city !== "" && (
                    <p className="text-sm text-red-500">{errors.city}</p>
                )}
            </div>
            <div className="flex flex-col">
                <p className="my-2 text-sm font-medium text-blueGray-700">
                    Last Name*
                </p>
                <input
                    required
                    type="text"
                    name="lastName"
                    tabIndex={1}
                    onKeyDown={(e) => {
                        if (e.code === "Space") {
                            e.preventDefault();
                        }
                    }}
                    value={selection.lastName}
                    onChange={(e) =>
                        setSelection({
                            ...selection,
                            lastName: e.target.value,
                        })
                    }
                    placeholder=""
                    className="mb-2 w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                />
                {errors.lastName !== "" && (
                    <p className="text-sm text-red-500">{errors.lastName}</p>
                )}
                <p className="my-2 text-sm font-medium text-blueGray-700">
                    Mobile*
                </p>
                <input
                    name="mobile"
                    required
                    tabIndex={3}
                    onKeyDown={(e) => {
                        if (e.code === "Space") {
                            e.preventDefault();
                        }
                    }}
                    type="text"
                    onKeyPress={(event) => {
                        // ignore "-", "+" and "e"
                        if (
                            event?.key === "-" ||
                            event?.key === "+" ||
                            event?.key === "e"
                        ) {
                            event.preventDefault();
                        }
                        // if character is not number then ignore except decimal point and
                        if (
                            event?.key !== "." &&
                            event?.key &&
                            Number.isNaN(+event.key)
                        ) {
                            event.preventDefault();
                        }
                    }}
                    value={selection.mobile}
                    onChange={(e) =>
                        setSelection({
                            ...selection,
                            mobile: e.target.value,
                        })
                    }
                    placeholder=""
                    className="mb-2 w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                />
                {errors.mobile !== "" && (
                    <p className="text-sm text-red-500">{errors.mobile}</p>
                )}
                <p className="my-2 text-sm font-medium text-blueGray-700">
                    Password*
                </p>
                <input
                    required
                    tabIndex={5}
                    type="password"
                    onKeyDown={(e) => {
                        if (e.code === "Space") {
                            e.preventDefault();
                        }
                    }}
                    name="password"
                    value={selection.password}
                    onChange={(e) =>
                        setSelection({
                            ...selection,
                            password: e.target.value,
                        })
                    }
                    placeholder=""
                    className="mb-2 w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                />
                {errors.password !== "" && (
                    <p className="text-sm text-red-500">{errors.password}</p>
                )}
                <p className="my-2 text-sm font-medium text-blueGray-700">
                    State*
                </p>
                <select
                    className="mb-2 w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                    name="state"
                    tabIndex={7}
                    value={selection.state}
                    onChange={(e) =>
                        setSelection({
                            ...selection,
                            state: e.target.value,
                        })
                    }
                    placeholder=""
                    required
                >
                    <option disabled selected />
                    {STATE_OPTIONS.map((state, index) => (
                        <option key={index} value={state}>
                            {state}
                        </option>
                    ))}
                </select>
                {errors.state !== "" && (
                    <p className="text-sm text-red-500">{errors.state}</p>
                )}
            </div>
        </div>
    );
};

export default Form;
