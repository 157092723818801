/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/template-indent */
/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import * as Types from '../../../types/generated-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/utils/apolloReactHooks';
const defaultOptions = {} as const;
export type UpdateUserDetailsMutationVariables = Types.Exact<{
  set: Types.Supertokens_User_Additional_Fields_Set_Input;
}>;


export type UpdateUserDetailsMutation = { __typename?: 'Mutation', update_supertokens_user_additional_fields?: { __typename?: 'supertokens_user_additional_fields_mutation_response', affected_rows: number } | null };

export type SendVerificationOtpMutationVariables = Types.Exact<{
  number: Types.Scalars['String']['input'];
  name: Types.Scalars['String']['input'];
}>;


export type SendVerificationOtpMutation = { __typename?: 'Mutation', sendVerificationOtp: { __typename?: 'GenericResponse', message?: string | null, success: boolean } };

export type VerifyOtpMutationVariables = Types.Exact<{
  otp: Types.Scalars['String']['input'];
  mobile: Types.Scalars['String']['input'];
}>;


export type VerifyOtpMutation = { __typename?: 'Mutation', verifyOtp: { __typename?: 'GenericResponse', message?: string | null, success: boolean } };

export type SignUpMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String']['input'];
  lastName: Types.Scalars['String']['input'];
  username: Types.Scalars['String']['input'];
  mobile: Types.Scalars['String']['input'];
  email: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
  state: Types.Scalars['String']['input'];
  city: Types.Scalars['String']['input'];
  utmSource?: Types.InputMaybe<Types.Scalars['String']['input']>;
  utmMedium?: Types.InputMaybe<Types.Scalars['String']['input']>;
  utmCampaign?: Types.InputMaybe<Types.Scalars['String']['input']>;
  utmContent?: Types.InputMaybe<Types.Scalars['String']['input']>;
  deviceType?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: { __typename?: 'GenericResponse', success: boolean, message?: string | null } };


export const UpdateUserDetailsDocument = gql`
    mutation UpdateUserDetails($set: supertokens_user_additional_fields_set_input!) {
  update_supertokens_user_additional_fields(_set: $set, where: {}) {
    affected_rows
  }
}
    `;
export type UpdateUserDetailsMutationFn = Apollo.MutationFunction<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>;

/**
 * __useUpdateUserDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateUserDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDetailsMutation, { data, loading, error }] = useUpdateUserDetailsMutation({
 *   variables: {
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateUserDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>(UpdateUserDetailsDocument, options);
      }
export type UpdateUserDetailsMutationHookResult = ReturnType<typeof useUpdateUserDetailsMutation>;
export type UpdateUserDetailsMutationResult = Apollo.MutationResult<UpdateUserDetailsMutation>;
export type UpdateUserDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>;
export const SendVerificationOtpDocument = gql`
    mutation SendVerificationOtp($number: String!, $name: String!) {
  sendVerificationOtp(number: $number, name: $name) {
    message
    success
  }
}
    `;
export type SendVerificationOtpMutationFn = Apollo.MutationFunction<SendVerificationOtpMutation, SendVerificationOtpMutationVariables>;

/**
 * __useSendVerificationOtpMutation__
 *
 * To run a mutation, you first call `useSendVerificationOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationOtpMutation, { data, loading, error }] = useSendVerificationOtpMutation({
 *   variables: {
 *      number: // value for 'number'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSendVerificationOtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendVerificationOtpMutation, SendVerificationOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendVerificationOtpMutation, SendVerificationOtpMutationVariables>(SendVerificationOtpDocument, options);
      }
export type SendVerificationOtpMutationHookResult = ReturnType<typeof useSendVerificationOtpMutation>;
export type SendVerificationOtpMutationResult = Apollo.MutationResult<SendVerificationOtpMutation>;
export type SendVerificationOtpMutationOptions = Apollo.BaseMutationOptions<SendVerificationOtpMutation, SendVerificationOtpMutationVariables>;
export const VerifyOtpDocument = gql`
    mutation verifyOtp($otp: String!, $mobile: String!) {
  verifyOtp(otp: $otp, mobile: $mobile) {
    message
    success
  }
}
    `;
export type VerifyOtpMutationFn = Apollo.MutationFunction<VerifyOtpMutation, VerifyOtpMutationVariables>;

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      otp: // value for 'otp'
 *      mobile: // value for 'mobile'
 *   },
 * });
 */
export function useVerifyOtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyOtpMutation, VerifyOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(VerifyOtpDocument, options);
      }
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>;
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>;
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($firstName: String!, $lastName: String!, $username: String!, $mobile: String!, $email: String!, $password: String!, $state: String!, $city: String!, $utmSource: String, $utmMedium: String, $utmCampaign: String, $utmContent: String, $deviceType: String) {
  signUp(
    firstName: $firstName
    lastName: $lastName
    username: $username
    mobile: $mobile
    email: $email
    password: $password
    state: $state
    city: $city
    utmSource: $utmSource
    utmMedium: $utmMedium
    utmCampaign: $utmCampaign
    utmContent: $utmContent
    deviceType: $deviceType
  ) {
    success
    message
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      username: // value for 'username'
 *      mobile: // value for 'mobile'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      utmSource: // value for 'utmSource'
 *      utmMedium: // value for 'utmMedium'
 *      utmCampaign: // value for 'utmCampaign'
 *      utmContent: // value for 'utmContent'
 *      deviceType: // value for 'deviceType'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;