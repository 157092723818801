import { createContext } from "react";

type ContextValue = {
    avatar?: string | null;
    city?: string | null;
    email?: string | null;
    firstName: string;
    lastName: string;
    fullName: string;
    mobile?: string | null;
    state?: string | null;
    user_id?: string | null;
    username: string;
    loading: boolean;
    xp?: any | null;
    level: number;
    badge: string;
    numberOfItemsUnlocked: number;
    totalNumberOfItems: number;
    currentXp: number;
    refetch: () => void;
    mobileVerified: boolean;
    emailVerified: boolean;
    isAdmin: boolean;
    isVisualImpaired: boolean;
    isLoggedIn: boolean;
    postalCode: string | null;
    country: string | null;
    dateOfBirth: string | null;
    gender: string | null;
    age: string | null;
    handleLogout: () => Promise<void>;
    paidUser: boolean;
};

const CurrentUserContext = createContext<ContextValue>({} as ContextValue);

export default CurrentUserContext;

export type { ContextValue };
