import React, { useEffect, useState } from "react";
import { useCurrentUser } from "@/context/currentUser";
import SessionContext from "./context";


const SessionProvider = ({ children }: { children: JSX.Element }) => {
    const [sessionExist, setSessionExist] = useState(false);

    const { isLoggedIn } = useCurrentUser();

    useEffect(() => {
        if (isLoggedIn) {
            setSessionExist(true);
        } else {
            setSessionExist(false);
        }
    }, [isLoggedIn]);

    return (
        <SessionContext.Provider value={sessionExist}>
            {children}
        </SessionContext.Provider>
    );
};

export default SessionProvider;