const formatNumberOfSeconds = (numberOfSeconds: number): string => {
    // send in hh:mm:ss
    const hours = Math.floor(numberOfSeconds / 3600);

    const minutes = Math.floor((numberOfSeconds - hours * 3600) / 60);

    const seconds = Math.floor(numberOfSeconds - hours * 3600 - minutes * 60);

    const hoursString = hours < 10 ? `0${hours}` : `${hours}`;

    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;

    const secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${hoursString}:${minutesString}:${secondsString}`;
};

export default formatNumberOfSeconds;
