import { getImageUrl } from "@/utils";

const SPEED_MATH_TYPE = [
    { value: "beginner", label: "Beginner", icon: getImageUrl("/images/beginner.svg") },
    { value: "intermediate", label: "Intermediate", icon: getImageUrl("/images/intermediate.svg") },
    { value: "advance", label: "Advance", icon: getImageUrl("/images/advanced.svg") },
];

const SPEED_QUANT_TYPE = [
    {
        value: "arithmetic",
        label: "Arithmetic",
        icon: getImageUrl("/images/beginner.svg"),
    },
    {
        value: "all_quant",
        label: "All Quant",
        icon: getImageUrl("/images/intermediate.svg"),
    },
];

const SPEED_VERBAL_TYPE = SPEED_QUANT_TYPE;

const SPEED_VOCAB_TYPE = [
    {
        label: "Vocabulary",
        value: "vocabulary",
        icon: getImageUrl("/images/beginner.svg"),
    },
    {
        label: "Grammar",
        value: "grammar",
        icon: getImageUrl("/images/intermediate.svg"),
    },
];

export {
    SPEED_MATH_TYPE,
    SPEED_QUANT_TYPE,
    SPEED_VERBAL_TYPE,
    SPEED_VOCAB_TYPE,
};
