import { isClient } from "@/utils";
import { ApolloClient, InMemoryCache, createHttpLink, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import axios from "axios";
import { createClient } from "graphql-ws";


const uri = process.env.NEXT_PUBLIC_API_DOMAIN + "/graphql";
const SUBSCRIPTION_URL = process.env.NEXT_PUBLIC_GRAPHQL_SUBSCRIPTIONS_URL;

export const getLink = () => {
    // Create an http link:
    const httpLink = createHttpLink({
        uri,
        headers: {
            "keep-alive": "true",
        },
        credentials: "include",
    });

    if (isClient()) {
        const client = createClient({
            url: SUBSCRIPTION_URL!,
            lazyCloseTimeout: 10 * 1000,
            lazy: true,
            connectionParams: async () => {
                if (!isClient()) return {};

                const cookiesData = await axios.get(
                    `${process.env.NEXT_PUBLIC_API_DOMAIN}/get-cookies`,
                    {
                        withCredentials: true,
                    }
                );

                if (cookiesData.data && cookiesData.data.auth_session) {
                    return {
                        headers: {
                            Authorization: `Bearer ${cookiesData.data.auth_session}`,
                            auth_session: cookiesData.data.auth_session,
                        },
                    };
                }

            },
        });

        const wsLink = new GraphQLWsLink(client);

        return split(
            ({ query }) => {
                const definition = getMainDefinition(query);

                return (
                    definition.kind === "OperationDefinition" &&
                    definition.operation === "subscription"
                );
            },
            wsLink,
            httpLink
        );
    }

    return httpLink;
};

const client = new ApolloClient({
    link: getLink(),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: "network-only",
            errorPolicy: "all",
        },
        mutate: {
            fetchPolicy: "network-only",
            errorPolicy: "all",
        },
        watchQuery: {
            fetchPolicy: "cache-and-network",
            errorPolicy: "all",
        },
    },
});

export default client;