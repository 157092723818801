/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useRouter } from "next/router";
import { useCurrentUser } from "../currentUser";
import CatResultContext from "./context";
import { useGetCatResponseDataLazyQuery, useGetCatApplicationDataLazyQuery } from "./operations.generated";


const CatResultProvider = ({ children }: { children: JSX.Element }) => {
    const router = useRouter();

    const { isLoggedIn } = useCurrentUser();

    const [applicationNo, setApplicationNo] = useState("");

    const [getCatApplicationData, { data: responseData }] =
        useGetCatApplicationDataLazyQuery();

    const responseSheetData = useMemo(() => {
        if (responseData?.getCatApplicationData?.data) {
            return JSON.parse(responseData?.getCatApplicationData?.data);
        }

        return null;
    }, [responseData]);

    useEffect(() => {
        if (applicationNo && applicationNo.length > 0)
            getCatApplicationData({
                variables: {
                    id: applicationNo,
                },
            });
    }, [applicationNo]);

    const [applicationId, setApplicationId] = useState(
        localStorage.getItem("applicationId") || ""
    );

    const [applicationData, setApplicationData] = useState({
        percentiles: { varc: 0, dilr: 0, qa: 0, total: 0 },
        marks: { varc: 0, dilr: 0, qa: 0, total: 0 },
    });

    useEffect(() => {
        const res = JSON.parse(localStorage.getItem("applicationData") || "{}");

        setApplicationData(res);
    }, [applicationId]);

    const [responseType, setResponseType] = useState(
        localStorage.getItem("responseType") || ""
    );

    const [getCatResult, { data }] = useGetCatResponseDataLazyQuery();

    useEffect(() => {
        if (data?.user_cat_response_sheet_data_by_pk) {
            setApplicationNo(
                data?.user_cat_response_sheet_data_by_pk?.application_no
            );

            getCatApplicationData({
                variables: {
                    id: data?.user_cat_response_sheet_data_by_pk
                        ?.application_no,
                },
                fetchPolicy: "network-only",
            });
        }
    }, [data]);

    useEffect(() => {
        if (router.route.includes("cat-") && applicationId) {
            getCatResult({
                variables: {
                    id: applicationId,
                },
                fetchPolicy: "network-only",
            });
        }
    }, [router.route]);

    const handleAddCatRegistration = async () => {
        if (applicationNo && applicationNo.length > 0) {
            try {
                await axios.post(
                    `${process.env.NEXT_PUBLIC_API_DOMAIN}/cat-registration`,
                    {
                        applicationNo,
                    }
                );
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        if (isLoggedIn && applicationNo) {
            handleAddCatRegistration();
        }
    }, [applicationNo, isLoggedIn]);

    return (
        <CatResultContext.Provider
            value={{
                applicationId,
                setApplicationId,
                applicationNo,
                handleAddCatRegistration,
                responseType,
                setResponseType,
                applicationData,
                setApplicationData,
                responseSheetData,
            }}
        >
            {children}
        </CatResultContext.Provider>
    );
};

export default CatResultProvider;