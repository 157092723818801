import React, { Fragment, useContext, memo } from "react";
import ForgotPasswordModal from "@/components/ForgotPasswordModal";
import LoginModal from "@/components/LoginModal";
import MobileVerificationModal from "@/components/MobileVerificationModal";
import RegisterModal from "@/components/RegisterModal";
import ModalsContext from "@/context/modals";

const Index = () => {
    const {
        loginOpen,
        registerOpen,
        mobileVerificationOpen,
        forgotPasswordOpen,
    } = useContext(ModalsContext);

    return (
        <Fragment>
            {loginOpen && <LoginModal />}
            {registerOpen && <RegisterModal />}
            {mobileVerificationOpen && <MobileVerificationModal />}
            {forgotPasswordOpen && <ForgotPasswordModal />}
        </Fragment>
    );
};

export default memo(Index);