import React, { useState, Fragment } from "react";
import useModalContext from "@/context/modals/useModalContext";
import { getImageUrl } from "@/utils";
import { Dialog, Transition } from "@headlessui/react";
import { useForgotPasswordMutation } from "./operations/operations.generated";

const ForgotPasswordModal = () => {
    const { setForgotPasswordOpen } = useModalContext();

    const closeModal = () => {
        setForgotPasswordOpen(false);
    };

    const [errorText, setErrorText] = useState("");

    const [mutate] = useForgotPasswordMutation({
        onCompleted(data) {
            if (data?.forgotPassword?.success) {
                setEmailSentSuccess(true);
            } else {
                setErrorText(
                    data?.forgotPassword?.message ?? "Something went wrong"
                );
            }
        },
    });

    const [emailSentSuccess, setEmailSentSuccess] = useState(false);

    const handleSubmit = () => {
        if (email === "") {
            setErrorText("Please enter your email");

            return;
        } else {
            setErrorText("");
            mutate({
                variables: {
                    email,
                },
            });
        }
    };

    const [email, setEmail] = useState("");

    return (
        <div>
            <Transition appear show={true} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded bg-white px-6 py-4 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="mb-4  w-full text-center text-2xl font-bold  text-blueGray-700"
                                    >
                                        Forgot Password?
                                    </Dialog.Title>
                                    <p className="w-full text-center font-medium text-blueGray-700">
                                        Enter your Email Address and we will
                                        send you a link to reset your password
                                    </p>
                                    <p className="my-2 text-sm font-medium text-blueGray-700">
                                        Email
                                    </p>
                                    <div className="relative mb-3 flex items-center justify-end">
                                        <input
                                            type="email"
                                            name="email"
                                            required
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            placeholder="debra.holt@example.com"
                                            className="w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                                        />
                                        {email !== "" && (
                                            <img
                                                src={getImageUrl(
                                                    "/images/close.svg"
                                                )}
                                                alt="Close Icon"
                                                width={30}
                                                height={30}
                                                className="absolute right-0 mr-2 cursor-pointer"
                                                onClick={() => setEmail("")}
                                            />
                                        )}
                                    </div>
                                    <button
                                        onClick={handleSubmit}
                                        className={`w-full rounded-lg  bg-blue-3 px-12 py-2 text-lg font-medium text-white outline-none ${
                                            emailSentSuccess
                                                ? "bg-opacity-50"
                                                : "bg-opacity-90"
                                        }`}
                                    >
                                        Reset Password
                                    </button>
                                    {errorText !== "" && (
                                        <p className="mt-2 text-sm font-medium text-red-500">
                                            {errorText}
                                        </p>
                                    )}
                                    {emailSentSuccess && (
                                        <p className="mt-2 text-sm font-medium text-green-500">
                                            A reset password link has been sent
                                            to your email
                                        </p>
                                    )}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default ForgotPasswordModal;
