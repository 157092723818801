import React, { useState } from "react";
import DailyContext from "./context";

const DailyProvider = ({ children }: { children: JSX.Element }) => {
    const [dailyData, setDailyData] = useState({});

    return (
        <DailyContext.Provider
            value={{
                dailyData,
                setDailyData,
            }}
        >
            {children}
        </DailyContext.Provider>
    );
};

export default DailyProvider;
