import React, { useEffect, useState, useContext } from "react";
import { UNPROTECTED_ROUTES, PROTECTED_ROUTES } from "@/constants";
import { useCurrentUser } from "@/context/currentUser";
import ModalsContext from "@/context/modals";
import { useRouter } from "next/router";
import routes from "routes";
import AccessDenied from "../AccessDenied";


const RouteGuard = ({ children }: { children: JSX.Element }) => {
    const router = useRouter();

    const [isLoading, setIsLoading] = useState(true);

    const { setLoginOpen } = useContext(ModalsContext);

    const { mobileVerified, isAdmin, isLoggedIn } = useCurrentUser();

    useEffect(() => {
        async function doesSessionExist() {
            if (
                !isLoggedIn &&
                PROTECTED_ROUTES.has(router.pathname) &&
                !router.pathname.includes("/auth/callback")
            ) {
                setIsLoading(false);
                setLoginOpen(true);
                router.push(routes.home().href);
            }

            setIsLoading(false);
        }
        setIsLoading(true);
        doesSessionExist();
    }, [router.pathname, mobileVerified]);

    if (UNPROTECTED_ROUTES.has(router.pathname)) {
        return children;
    }

    if (isLoading && !router.route.includes("info/cat-preparation"))
        return <div>Loading...</div>;

    if (isAdmin) {
        return router.pathname.includes("/admin") ||
            UNPROTECTED_ROUTES.has(router.pathname) ||
            router.pathname.includes("/compete/result/") ||
            router.pathname.includes("/channel") ||
            router.asPath.includes("/dashboard/planner") ||
            router.pathname.includes("/webex") ||
            router.pathname.includes("/plans") ? (
            children
        ) : (
            <AccessDenied />
        );
    }

    if (router.pathname.includes("/admin")) {
        return isAdmin ? children : <AccessDenied />;
    }

    return children;
};

export default RouteGuard;