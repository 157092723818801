import React from "react";
import { getImageUrl } from "@/utils";
import { useRouter } from "next/router";
import useGoogleLogin from "./hooks/useGoogleLogin";

const SignInWithGoogle = () => {
    const { handleGoogleSignIn } = useGoogleLogin();

    const router = useRouter();

    return (
        <button
            className="text-blue-700 mt-2 flex w-full items-center justify-center rounded-lg border border-gray-8 bg-white px-12 py-2 text-base font-medium"
            onClick={() => {
                if (router.pathname.includes("cat-result")) {
                    localStorage.setItem("catFlow", "true");
                } else {
                    localStorage.setItem("catFlow", "false");
                }

                handleGoogleSignIn();
            }}
        >
            <img
                alt=""
                src={getImageUrl("/images/Google.svg")}
                className=" mr-2 h-5 w-5"
            />
            Sign in with google
        </button>
    );
};

export default SignInWithGoogle;
