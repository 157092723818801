import { ActivityTypes } from "@/enums";
import { getImageUrl } from "@/utils";
import { StaticImageData } from "next/image";

const ACTIVITY_TYPES_IMAGES: {
    readonly [key in ActivityTypes]: string | StaticImageData;
} = {
    [ActivityTypes.LESSON]: getImageUrl("/images/ActivityIcons/lesson.png"),
    [ActivityTypes.PRACTICE]: getImageUrl("/images/ActivityIcons/practice.png"),
    [ActivityTypes.CONCEPT]: getImageUrl("/images/ActivityIcons/practice.png"),
    [ActivityTypes.COMPETE]: getImageUrl("/images/ActivityIcons/compete.png"),
};

export default ACTIVITY_TYPES_IMAGES;
