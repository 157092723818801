import React, { useState } from "react";
import moment from "moment";
import { useCurrentUser } from "../currentUser";
import PackageSubscriptionContext from "./context";
import { useGetPurchasedPlanQuery } from "./operations.generated";

const PackageSubscriptionProvider = ({
    children,
}: {
    children: JSX.Element;
}) => {
    const [packageSubscription, setPackageSubscription] = useState({});

    const { isLoggedIn } = useCurrentUser();

    const currentDate = moment();

    const { refetch } = useGetPurchasedPlanQuery({
        skip: !isLoggedIn,
        onCompleted(data) {
            if (data?.packages_payment_transaction?.length > 0) {
                setPackageSubscription({
                    ...data?.packages_payment_transaction?.[0]
                        ?.subscriptions_subscriptionplan,
                    price: data?.packages_payment_transaction?.[0]?.price,
                    gst: data?.packages_payment_transaction?.[0]?.gst,
                    group_id: data?.packages_payment_transaction?.[0]?.group_id,
                    disabledLiveClasses:
                        data?.packages_payment_transaction[0]
                            ?.subscriptions_subscriptionplan?.package_content
                            ?.disable_live_classes ?? false,
                    initial_price:
                        data?.packages_payment_transaction?.[0]?.initial_price,
                    isUpgradeAvailable:
                        data?.packages_payment_transaction?.[0]
                            ?.subscriptions_subscriptionplan
                            ?.subscriptions_subscriptionplan_upgradable_plans
                            ?.length > 0,
                    expired:
                        currentDate?.diff(
                            moment(
                                data?.packages_payment_transaction[0]
                                    ?.subscriptions_subscriptionplan
                                    ?.package_content?.expiry_date
                            ),
                            "seconds"
                        ) <= 0,
                });
            }
        },
    });

    return (
        <PackageSubscriptionContext.Provider
            value={{
                packageSubscription,
                setPackageSubscription,
                refetchPackageSubscription: refetch,
            }}
        >
            {children}
        </PackageSubscriptionContext.Provider>
    );
};

export default PackageSubscriptionProvider;
