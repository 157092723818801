/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/template-indent */
/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import * as Types from '../../types/generated-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/utils/apolloReactHooks';
const defaultOptions = {} as const;
export type GetUserAdditionalDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserAdditionalDataQuery = { __typename?: 'Query', supertokens_user_additional_fields: Array<{ __typename?: 'supertokens_user_additional_fields', avatar?: string | null, city?: string | null, email?: string | null, first_name?: string | null, last_name?: string | null, mobile?: string | null, state?: string | null, user_id?: string | null, username?: string | null, postal_code?: string | null, country?: string | null, date_of_birth?: string | null, age?: string | null, gender?: string | null, xp?: any | null, is_visually_impaired: boolean, role: string, mobile_verified: boolean, email_verified: boolean, all_auth_recipe_user?: { __typename?: 'supertokens_all_auth_recipe_users', packages_payment_transactions: Array<{ __typename?: 'packages_payment_transaction', payment: boolean }> } | null, emailVerified?: { __typename?: 'supertokens_emailverification_verified_emails', user_id: string } | null, thirdparty_users?: { __typename?: 'supertokens_thirdparty_users', user_id: any } | null }> };

export type GetUserXpTransactionSummariesSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserXpTransactionSummariesSubscription = { __typename?: 'Subscription', user_xp_transaction_summaries: Array<{ __typename?: 'user_xp_transaction_summaries', xp: any }> };

export type LogoutQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LogoutQuery = { __typename?: 'Query', logout: { __typename?: 'GenericResponse', success: boolean, message?: string | null } };

export type UpdateLastActiveQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateLastActiveQuery = { __typename?: 'Query', updateLastActive: { __typename?: 'GenericResponse', success: boolean, message?: string | null } };

export type ValidateSessionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ValidateSessionQuery = { __typename?: 'Query', validateSession: { __typename?: 'GenericResponse', success: boolean, message?: string | null } };


export const GetUserAdditionalDataDocument = gql`
    query GetUserAdditionalData {
  supertokens_user_additional_fields(limit: 1) {
    avatar
    city
    email
    first_name
    last_name
    mobile
    state
    user_id
    username
    postal_code
    country
    date_of_birth
    age
    gender
    xp
    is_visually_impaired
    all_auth_recipe_user {
      packages_payment_transactions(where: {payment: {_eq: true}}) {
        payment
      }
    }
    emailVerified {
      user_id
    }
    role
    mobile_verified
    email_verified
    thirdparty_users {
      user_id
    }
  }
}
    `;

/**
 * __useGetUserAdditionalDataQuery__
 *
 * To run a query within a React component, call `useGetUserAdditionalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAdditionalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAdditionalDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAdditionalDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserAdditionalDataQuery, GetUserAdditionalDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserAdditionalDataQuery, GetUserAdditionalDataQueryVariables>(GetUserAdditionalDataDocument, options);
      }
export function useGetUserAdditionalDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserAdditionalDataQuery, GetUserAdditionalDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserAdditionalDataQuery, GetUserAdditionalDataQueryVariables>(GetUserAdditionalDataDocument, options);
        }
export type GetUserAdditionalDataQueryHookResult = ReturnType<typeof useGetUserAdditionalDataQuery>;
export type GetUserAdditionalDataLazyQueryHookResult = ReturnType<typeof useGetUserAdditionalDataLazyQuery>;
export type GetUserAdditionalDataQueryResult = Apollo.QueryResult<GetUserAdditionalDataQuery, GetUserAdditionalDataQueryVariables>;
export const GetUserXpTransactionSummariesDocument = gql`
    subscription getUserXpTransactionSummaries {
  user_xp_transaction_summaries {
    xp
  }
}
    `;

/**
 * __useGetUserXpTransactionSummariesSubscription__
 *
 * To run a query within a React component, call `useGetUserXpTransactionSummariesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUserXpTransactionSummariesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserXpTransactionSummariesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetUserXpTransactionSummariesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetUserXpTransactionSummariesSubscription, GetUserXpTransactionSummariesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<GetUserXpTransactionSummariesSubscription, GetUserXpTransactionSummariesSubscriptionVariables>(GetUserXpTransactionSummariesDocument, options);
      }
export type GetUserXpTransactionSummariesSubscriptionHookResult = ReturnType<typeof useGetUserXpTransactionSummariesSubscription>;
export type GetUserXpTransactionSummariesSubscriptionResult = Apollo.SubscriptionResult<GetUserXpTransactionSummariesSubscription>;
export const LogoutDocument = gql`
    query logout {
  logout {
    success
    message
  }
}
    `;

/**
 * __useLogoutQuery__
 *
 * To run a query within a React component, call `useLogoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogoutQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, options);
      }
export function useLogoutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, options);
        }
export type LogoutQueryHookResult = ReturnType<typeof useLogoutQuery>;
export type LogoutLazyQueryHookResult = ReturnType<typeof useLogoutLazyQuery>;
export type LogoutQueryResult = Apollo.QueryResult<LogoutQuery, LogoutQueryVariables>;
export const UpdateLastActiveDocument = gql`
    query updateLastActive {
  updateLastActive {
    success
    message
  }
}
    `;

/**
 * __useUpdateLastActiveQuery__
 *
 * To run a query within a React component, call `useUpdateLastActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateLastActiveQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateLastActiveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UpdateLastActiveQuery, UpdateLastActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UpdateLastActiveQuery, UpdateLastActiveQueryVariables>(UpdateLastActiveDocument, options);
      }
export function useUpdateLastActiveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UpdateLastActiveQuery, UpdateLastActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UpdateLastActiveQuery, UpdateLastActiveQueryVariables>(UpdateLastActiveDocument, options);
        }
export type UpdateLastActiveQueryHookResult = ReturnType<typeof useUpdateLastActiveQuery>;
export type UpdateLastActiveLazyQueryHookResult = ReturnType<typeof useUpdateLastActiveLazyQuery>;
export type UpdateLastActiveQueryResult = Apollo.QueryResult<UpdateLastActiveQuery, UpdateLastActiveQueryVariables>;
export const ValidateSessionDocument = gql`
    query validateSession {
  validateSession {
    success
    message
  }
}
    `;

/**
 * __useValidateSessionQuery__
 *
 * To run a query within a React component, call `useValidateSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useValidateSessionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidateSessionQuery, ValidateSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ValidateSessionQuery, ValidateSessionQueryVariables>(ValidateSessionDocument, options);
      }
export function useValidateSessionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidateSessionQuery, ValidateSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ValidateSessionQuery, ValidateSessionQueryVariables>(ValidateSessionDocument, options);
        }
export type ValidateSessionQueryHookResult = ReturnType<typeof useValidateSessionQuery>;
export type ValidateSessionLazyQueryHookResult = ReturnType<typeof useValidateSessionLazyQuery>;
export type ValidateSessionQueryResult = Apollo.QueryResult<ValidateSessionQuery, ValidateSessionQueryVariables>;