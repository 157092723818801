/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/template-indent */
/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import * as Types from '../../types/generated-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/utils/apolloReactHooks';
const defaultOptions = {} as const;
export type GetPurchasedPlanQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPurchasedPlanQuery = { __typename?: 'Query', packages_payment_transaction: Array<{ __typename?: 'packages_payment_transaction', price: any, id: number, gst: any, initial_price: any, group_id?: number | null, subscriptions_subscriptionplan: { __typename?: 'subscriptions_subscriptionplan', plan_name: string, id: any, striked_price?: any | null, plan_description?: string | null, cost?: any | null, is_a_top_pack: boolean, most_popular: boolean, unlimited_speed_quant: boolean, unlimited_speed_verbal: boolean, unlimited_speed_concept: boolean, package_content?: { __typename?: 'packages_content', expiry_date?: any | null, message?: string | null, disable_live_classes: boolean } | null, subscriptions_subscriptionplan_upgradable_plans: Array<{ __typename?: 'subscriptions_subscriptionplan_upgradable_plans', id: number, to_subscriptionplan_id: any }> } }> };


export const GetPurchasedPlanDocument = gql`
    query getPurchasedPlan {
  packages_payment_transaction(
    where: {payment: {_eq: true}}
    order_by: {updated_at: desc}
    limit: 1
  ) {
    price
    id
    gst
    initial_price
    group_id
    subscriptions_subscriptionplan {
      plan_name
      id
      striked_price
      plan_description
      cost
      is_a_top_pack
      most_popular
      plan_description
      unlimited_speed_quant
      unlimited_speed_verbal
      unlimited_speed_concept
      package_content {
        expiry_date
        message
        disable_live_classes
      }
      id
      subscriptions_subscriptionplan_upgradable_plans {
        id
        to_subscriptionplan_id
      }
    }
  }
}
    `;

/**
 * __useGetPurchasedPlanQuery__
 *
 * To run a query within a React component, call `useGetPurchasedPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchasedPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchasedPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPurchasedPlanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchasedPlanQuery, GetPurchasedPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPurchasedPlanQuery, GetPurchasedPlanQueryVariables>(GetPurchasedPlanDocument, options);
      }
export function useGetPurchasedPlanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchasedPlanQuery, GetPurchasedPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPurchasedPlanQuery, GetPurchasedPlanQueryVariables>(GetPurchasedPlanDocument, options);
        }
export type GetPurchasedPlanQueryHookResult = ReturnType<typeof useGetPurchasedPlanQuery>;
export type GetPurchasedPlanLazyQueryHookResult = ReturnType<typeof useGetPurchasedPlanLazyQuery>;
export type GetPurchasedPlanQueryResult = Apollo.QueryResult<GetPurchasedPlanQuery, GetPurchasedPlanQueryVariables>;