const SUPER_DOMAINS = [
    {
        id: 1,
        name: "home",
        slug:"home"
    },
    {
        id: 2,
        name: "Quant Practice",
        slug:"quantitative-ability"
    },
    {
        id: 3,
        name: "Timed Test",
        slug:"timed-test"
    },
    {
        id: 4,
        name: "Verbal Practice",
        slug:"verbal-ability"
    },
    {
        id: 5,
        name: "DILR Practice",
        slug:"di-lr"
    },
    {
        id: 6,
        name: "Live Classes",
        slug:"live-classes"
    },
];

export default SUPER_DOMAINS;