const DEFAULT_PLANS = [
    {
        id: "1",
        plan_name: "MBA Basic 2024",
        cost: "4,999",
        plan_description:
            "Access till 15th January 2025, Interview Preparation access till 31st March 2025",
        items: [
            "25 Mock CATs",
            "45 CAT Sectional Tests",
            "30 OMETs (XAT, SNAP, etc.)",
            "Access to All Speed Modules",
            "400+ Quizzes of the Day",
        ],
    },
    {
        id: "2",
        plan_name: "MBA Classroom 2024",
        plan_description:
            "Access till 15th January 2025, Interview Preparation access till 31st March 2025",
        cost: "14999",
        items: [
            "25 Mock CATs",
            "45 CAT Sectional Tests",
            "30 OMETs (XAT, SNAP, etc.)",
            "Access to All Speed Modules",
            "400+ Quizzes of the Day",
            "Telegram group for Mentorship, Doubts and Peer-based Learning",
            "150+ hours of Video Lessons and Text Lessons",
            "10000+ Solved Questions with 2400+ Practice Questions",
            "600+ Hours of Live Interactive Classes",
        ],
    },
    {
        id: "3",
        plan_name: "MBA Classroom 2024",
        plan_description:
            "Access till 15th January 2025, Interview Preparation access till 31st March 2025",
        cost: "24,999",
        items: [
            "25 Mock CATs",
            "45 CAT Sectional Tests",
            "30 OMETs (XAT, SNAP, etc.)",
            "Access to All Speed Modules",
            "400+ Quizzes of the Day",
            "Telegram group for Mentorship, Doubts and Peer-based Learning",
            "150+ hours of Video Lessons and Text Lessons",
            "10000+ Solved Questions with 2400+ Practice Questions",
            "600+ Hours of Live Interactive Classes",
            "Access to All Year's Class Recordings",
        ],
    },
    {
        id: "4",
        plan_name: "MBA Unlimited 2024",
        plan_description:
            "Access till 15th January 2025, Interview Preparation access till 31st March 2025",
        cost: "34999",
        items: [
            "25 Mock CATs",
            "45 CAT Sectional Tests",
            "30 OMETs (XAT, SNAP, etc.)",
            "Access to All Speed Modules",
            "400+ Quizzes of the Day",
            "Telegram group for Mentorship, Doubts and Peer-based Learning",
            "150+ hours of Video Lessons and Text Lessons",
            "10000+ Solved Questions with 2400+ Practice Questions",
            "600+ Hours of Live Interactive Classes",
            "Access to All Year's Class Recordings",
            "Customised Study Plan CAT, XAT, etc.",
            "12 Printed Books Curated by our Mentors",
            "Periodic Doubt-Clearing Sessions",
            "WAT-GD-Personal Interview Training",
            "VARC Reading and Author's PoV with Topper's guidance",
            "Mock Analysis Sessions",
            "One on One Doubt-Clearing Sessions",
            "One on One WAT-GD-Personal Interview Training",
            "One on One VARC Reading and Author's PoV with Topper's guidance",
            "One on One Mock Analysis Sessions",
        ],
    },
];

export default DEFAULT_PLANS;
