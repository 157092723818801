/* eslint-disable unicorn/consistent-function-scoping */

/* eslint-disable max-lines */
import React, { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { isProduction } from "@/constants";
import { getBadgeInfo, getLoadingFromNetworkStatus } from "@/utils";
import { isClient } from "@/utils";
import { useRouter } from "next/router";
import routes from "routes";
import CurrentUserContext from "./context";
import {
    useGetUserAdditionalDataQuery,
    useLogoutLazyQuery,
    useUpdateLastActiveLazyQuery,
    useValidateSessionLazyQuery,
    useGetUserXpTransactionSummariesSubscription,
} from "./operations.generated";

const CurrentUserProvider = ({ children }: { children: JSX.Element }) => {
    const isAdmin = localStorage.getItem("userRole") === "admin";

    const router = useRouter();

    const { data, refetch, loading, networkStatus } =
        useGetUserAdditionalDataQuery({
            skip: router.route.includes("/info/cat-preparation"),
        });

    const [updateLastActive] = useUpdateLastActiveLazyQuery();

    const [validateSession] = useValidateSessionLazyQuery({
        onCompleted(data) {
            if (data?.validateSession?.success) {
                localStorage.setItem("isLoggedIn", "true");
            } else {
                if (data?.validateSession?.message === "Invalid session") {
                    localStorage.clear();
                    alert(
                        "You have been signed out from this system as you have logged in through another system, if this issue persists please check your device time."
                    );
                    router.push(routes.home().href);

                    return;
                }
                localStorage.clear();
                refetch();
            }
        },
    });

    const [logout] = useLogoutLazyQuery({
        onCompleted(data) {
            if (data?.logout?.success) {
                localStorage.clear();
                refetch();

                router.push(routes.home().href);
            } else {
                toast.dismiss();
                toast.error(data?.logout?.message);
            }
        },
    });

    const emailVerified =
        data?.supertokens_user_additional_fields?.[0]?.email_verified;

    const currentUserLoading = getLoadingFromNetworkStatus(networkStatus);

    const handleLogout = async () => {
        logout({});
    };

    useEffect(() => {
        if (
            router.asPath.includes("/result") ||
            router.asPath.includes("/learn") ||
            router.asPath.includes("/profile")
        ) {
            refetch();
        }
    }, [router.asPath]);

    const { data: levelData } = useGetUserXpTransactionSummariesSubscription({
        skip: router.route.includes("/info/cat-preparation"),
    });

    const levelRelatedData = useMemo(() => {
        if (levelData) {
            const totalXp =
                levelData?.user_xp_transaction_summaries?.[0]?.xp ?? 0;

            return getBadgeInfo(totalXp);
        }

        return {
            level: 0,
            badge: "",
            currentXp: 0,
            numberOfItemsUnlocked: 0,
            totalNumberOfItems: 0,
        };
    }, [levelData]);

    const currentUser = useMemo(() => {
        if (data) {
            localStorage.setItem("currentUser", JSON.stringify(data));

            return data?.supertokens_user_additional_fields?.[0];
        } else {
            let localValue = null;

            if (isClient() && localStorage.getItem("currentUser") !== null) {
                localValue = JSON.parse(
                    localStorage.getItem("currentUser") ?? ""
                );
            }

            return localValue;
        }
    }, [data]);

    useEffect(() => {
        if (data?.supertokens_user_additional_fields) {
            updateLastActive({});
            validateSession({});
        }
    }, [data]);

    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

    useEffect(() => {
        if (data && data?.supertokens_user_additional_fields?.length > 0) {
            const isAuthRoute = router.pathname.startsWith("/auth");

            if (router.pathname.includes("404")) {
                return;
            }

            const isCatFlow = router.pathname.includes("cat");

            if (isAdmin) return;

            if (
                isProduction && // check if email is verified if not then redirect to verify email page
                !emailVerified
            ) {
                if (!isAuthRoute) router.push(routes.verifyEmail().href);

                return;
            }

            if (currentUserLoading) return;

            if (!currentUser && !currentUserLoading) {
                toast.dismiss();
                toast.error("Please Refresh the Page");

                if (router.route !== routes.home().href) {
                    router.push(routes.home().href);
                }

                return;
            }

            if (
                isProduction &&
                !currentUser?.username &&
                !currentUser?.first_name &&
                !isCatFlow
            ) {
                if (!router.pathname.includes(routes.profileData().href))
                    router.push(routes.profileData().href);

                return;
            }

            if (
                // isProduction &&
                currentUser &&
                !currentUser?.mobile_verified &&
                isLoggedIn &&
                !isAuthRoute
            ) {
                //   check if mobile is verified if not then redirect to verify mobile page
                router.push("/?verify-mobile");

                return;
            }

            if (
                isProduction &&
                router.route.includes("verify-mobile") &&
                currentUser?.mobile_verified
            ) {
                router.push(router.pathname);

                return;
            }

            const catResult = localStorage.getItem("catResult");

            if (catResult && currentUser) {
                localStorage.removeItem("catResult");
                router.push(routes.catShowMarks());
            }
        }
    }, [
        emailVerified,
        currentUser,
        currentUserLoading,
        isAdmin,
        data,
        refetch,
        loading,
        router.pathname,
        isLoggedIn,
    ]);

    const isLoading = currentUserLoading;

    useEffect(() => {
        if (
            isLoggedIn &&
            !loading &&
            !isLoading &&
            !data?.supertokens_user_additional_fields?.[0]?.user_id
        ) {
            localStorage.clear();
            window.location.reload();
        }
    }, [data]);

    if (isLoading && !router.route.includes("/info/cat-preparation")) {
        return <div>Loading...</div>;
    }

    return (
        <CurrentUserContext.Provider
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={{
                ...currentUser,
                ...levelRelatedData,
                loading: currentUserLoading,
                isVisualImpaired: currentUser?.is_visually_impaired ?? false,
                firstName: currentUser?.first_name ?? "",
                lastName: currentUser?.last_name ?? "",
                fullName: currentUser
                    ? `${currentUser?.first_name} ${currentUser?.last_name}`
                    : null,
                avatar: currentUser?.avatar ?? "",
                postalCode: currentUser?.postal_code ?? "",
                country: currentUser?.country ?? "",
                dateOfBirth: currentUser?.date_of_birth ?? "",
                gender: currentUser?.gender ?? "",
                age: currentUser?.age ?? "",
                refetch: refetch,
                mobileVerified: currentUser?.mobile_verified ?? false,
                emailVerified,
                isAdmin,
                isLoggedIn,
                handleLogout,
                paidUser:
                    currentUser?.all_auth_recipe_user
                        ?.packages_payment_transactions.length > 0,
            }}
        >
            {children}
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserProvider;
