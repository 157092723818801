const formatTime = (time: number | string): string => {
    if (!time) return "0s";

    if (typeof time === "number") {
        const minutes = Math.floor(time / 60);

        const seconds = time % 60;

        return `${minutes > 0 ? minutes + "m " : " "}${seconds}s`;
    } else {
        const [, m, s] = time ? time.split(":") : [0, 0, 0];

        return `${Number(m) > 0 ? Number(m) + "m " : " "}${Number(s)}s`;
    }
};

export default formatTime;
