/* eslint-disable max-lines */
import React, { useState, Fragment, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { useCurrentUser } from "@/context/currentUser";
import ModalsContext from "@/context/modals";
import { getImageUrl } from "@/utils";
import { Dialog, Transition } from "@headlessui/react";
import SignInWithGoogle from "../SignInWithGoogle";
import Spinner from "../Spinner";
import { useLoginMutation } from "./operations/operations.generated";

const LoginModal = () => {
    const [loading, setLoading] = useState(false);

    const { refetch } = useCurrentUser();

    const [mutate] = useLoginMutation({
        onCompleted(data) {
            if (data?.login?.success) {
                localStorage.setItem("isLoggedIn", "true");
                localStorage.setItem("userRole", data.login?.role ?? "user");
                refetch();
                setLoading(false);
                setLoginOpen(false);
            } else {
                toast.dismiss();
                toast.error(
                    data?.login?.message ?? "Oops! Something went wrong."
                );
                localStorage.removeItem("userRole");
                setLoading(false);
            }
        },
        onError() {
            toast.dismiss();
            toast.error("Oops! Something went wrong.");
            setLoading(false);
        },
    });

    const { setLoginOpen, setRegisterOpen, setForgotPasswordOpen } =
        useContext(ModalsContext);

    const closeModal = () => {
        setLoginOpen(false);
    };

    const [errorText, setErrorText] = useState("");

    const [showPassword, setShowPassword] = useState(false);

    const [selection, setSelection] = useState({
        email: "",
        password: "",
        isRemember: false,
    });

    const signIn = async () => {
        try {
            mutate({
                variables: {
                    email: selection.email,
                    password: selection.password,
                },
            });
        } catch {
            setLoading(false);

            toast.error("Oops! Something went wrong.");
        }
    };

    const handleSubmit = () => {
        if (selection.email === "") {
            setErrorText("Please enter your email");

            return;
        } else if (selection.password === "") {
            setErrorText("Please enter your password");

            return;
        } else {
            setErrorText("");
            setLoading(true);
            signIn();
        }
    };

    const passwordRef = useRef<HTMLInputElement | null>(null);

    const loginButtonRef = useRef<HTMLButtonElement | null>(null);

    return (
        <div>
            <Transition appear show={true} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded bg-white px-6 py-4 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="mb-4  w-full text-center text-2xl font-bold  text-blueGray-700"
                                    >
                                        Login
                                    </Dialog.Title>
                                    <p className="my-2 text-sm font-medium text-blueGray-700">
                                        Email
                                    </p>
                                    <div className="relative mb-3 flex items-center justify-end">
                                        <input
                                            value={selection.email}
                                            onChange={(e) =>
                                                setSelection({
                                                    ...selection,
                                                    email: e.target.value,
                                                })
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    passwordRef?.current?.focus();
                                                }
                                            }}
                                            placeholder="debra.holt@example.com"
                                            className="w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                                        />
                                        {selection.email !== "" && (
                                            <img
                                                src={getImageUrl(
                                                    "/images/close.svg"
                                                )}
                                                alt="Close Icon"
                                                width={30}
                                                height={30}
                                                className="absolute right-0 mr-2 cursor-pointer"
                                                onClick={() =>
                                                    setSelection({
                                                        ...selection,
                                                        email: "",
                                                    })
                                                }
                                            />
                                        )}
                                    </div>
                                    <p className="my-2 text-sm font-medium text-blueGray-700">
                                        Password
                                    </p>
                                    <div className="relative mb-3 flex items-center justify-end">
                                        <input
                                            value={selection.password}
                                            ref={passwordRef}
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            onChange={(e) =>
                                                setSelection({
                                                    ...selection,
                                                    password: e.target.value,
                                                })
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    loginButtonRef?.current?.focus();
                                                }
                                            }}
                                            placeholder="debra.holt@example.com"
                                            className="w-full rounded-lg border border-gray-8 px-4 py-2 pr-10 outline-none"
                                        />
                                        {showPassword ? (
                                            <img
                                                src={getImageUrl(
                                                    "/images/hide-eye.svg"
                                                )}
                                                alt=""
                                                width={30}
                                                height={30}
                                                className="absolute right-0 mr-2 cursor-pointer"
                                                onClick={() =>
                                                    setShowPassword(true)
                                                }
                                            />
                                        ) : (
                                            <img
                                                src={getImageUrl(
                                                    "/images/hide-eye.svg"
                                                )}
                                                alt=""
                                                width={30}
                                                height={30}
                                                className="absolute right-0 mr-2 cursor-pointer"
                                                onClick={() =>
                                                    setShowPassword(true)
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="mb-3 mt-2 flex items-center justify-between">
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                className="mr-2 cursor-pointer border-gray-8"
                                                checked={selection.isRemember}
                                                onChange={(e) =>
                                                    setSelection({
                                                        ...selection,
                                                        isRemember:
                                                            e.target.checked,
                                                    })
                                                }
                                            />
                                            <p className="text-sm font-medium text-blueGray-700">
                                                Remember me
                                            </p>
                                        </div>
                                        <p
                                            onClick={() => {
                                                setForgotPasswordOpen(true);
                                                closeModal();
                                            }}
                                            className="mb-2 cursor-pointer text-sm font-medium text-link"
                                        >
                                            Forgot password?
                                        </p>
                                    </div>
                                    {errorText !== "" && (
                                        <p className="mb-2 text-sm font-medium text-red-500">
                                            {errorText}
                                        </p>
                                    )}
                                    <button
                                        disabled={loading}
                                        onClick={handleSubmit}
                                        ref={loginButtonRef}
                                        className=" w-full rounded-lg bg-blue-3 bg-opacity-90 px-12 py-2 text-lg font-medium text-white outline-none"
                                    >
                                        {loading ? <Spinner /> : "Sign in"}
                                    </button>
                                    <SignInWithGoogle />
                                    <div
                                        className="mt-6 flex items-center justify-center pb-3"
                                        onClick={() => {
                                            setLoginOpen(false);
                                            setRegisterOpen(true);
                                        }}
                                    >
                                        <p className="font-medium text-blue-3">
                                            Don&apos;t have an account?
                                        </p>
                                        <p className="ml-2 cursor-pointer text-link">
                                            Sign up
                                        </p>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default LoginModal;