/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/template-indent */
/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import * as Types from '../../types/generated-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/utils/apolloReactHooks';
const defaultOptions = {} as const;
export type GetCatResponseDataQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetCatResponseDataQuery = { __typename?: 'Query', user_cat_response_sheet_data_by_pk?: { __typename?: 'user_cat_response_sheet_data', application_no: string } | null };

export type GetCatApplicationDataQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetCatApplicationDataQuery = { __typename?: 'Query', getCatApplicationData: { __typename?: 'GetCatApplicationDataResponse', success: boolean, message?: string | null, data?: string | null } };


export const GetCatResponseDataDocument = gql`
    query getCatResponseData($id: uuid!) {
  user_cat_response_sheet_data_by_pk(id: $id) {
    application_no
  }
}
    `;

/**
 * __useGetCatResponseDataQuery__
 *
 * To run a query within a React component, call `useGetCatResponseDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatResponseDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatResponseDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCatResponseDataQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCatResponseDataQuery, GetCatResponseDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCatResponseDataQuery, GetCatResponseDataQueryVariables>(GetCatResponseDataDocument, options);
      }
export function useGetCatResponseDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCatResponseDataQuery, GetCatResponseDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCatResponseDataQuery, GetCatResponseDataQueryVariables>(GetCatResponseDataDocument, options);
        }
export type GetCatResponseDataQueryHookResult = ReturnType<typeof useGetCatResponseDataQuery>;
export type GetCatResponseDataLazyQueryHookResult = ReturnType<typeof useGetCatResponseDataLazyQuery>;
export type GetCatResponseDataQueryResult = Apollo.QueryResult<GetCatResponseDataQuery, GetCatResponseDataQueryVariables>;
export const GetCatApplicationDataDocument = gql`
    query getCatApplicationData($id: String!) {
  getCatApplicationData(applicationId: $id) {
    success
    message
    data
  }
}
    `;

/**
 * __useGetCatApplicationDataQuery__
 *
 * To run a query within a React component, call `useGetCatApplicationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatApplicationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatApplicationDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCatApplicationDataQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCatApplicationDataQuery, GetCatApplicationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCatApplicationDataQuery, GetCatApplicationDataQueryVariables>(GetCatApplicationDataDocument, options);
      }
export function useGetCatApplicationDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCatApplicationDataQuery, GetCatApplicationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCatApplicationDataQuery, GetCatApplicationDataQueryVariables>(GetCatApplicationDataDocument, options);
        }
export type GetCatApplicationDataQueryHookResult = ReturnType<typeof useGetCatApplicationDataQuery>;
export type GetCatApplicationDataLazyQueryHookResult = ReturnType<typeof useGetCatApplicationDataLazyQuery>;
export type GetCatApplicationDataQueryResult = Apollo.QueryResult<GetCatApplicationDataQuery, GetCatApplicationDataQueryVariables>;