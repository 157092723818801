type GroupDataForEachDayInAWeekProps = {
    createdAt: string;
}[];

const groupDataForEachDayInAWeek = (data: GroupDataForEachDayInAWeekProps) => {
    const today = new Date();

    const weekDays = Array.from({ length: 7 }, (_, i) => {
        const day = new Date(today);

        day.setDate(today.getDate() - i);

        return day;
    }).reverse();

    const groupedData = weekDays.map((day) => {
        const dayData = data.filter((item) => {
            const itemDate = new Date(item.createdAt);

            return (
                itemDate.getDate() === day.getDate() &&
                itemDate.getMonth() === day.getMonth() &&
                itemDate.getFullYear() === day.getFullYear()
            );
        });

        return dayData;
    });

    // aggregate the count
    const aggregatedData = [];

    for (const dayData of groupedData) {
        aggregatedData.push(dayData.length);
    }

    return {
        data: aggregatedData,
        labels: weekDays.map((day) => {
            const dayName = day.toLocaleString("default", { weekday: "short" });

            return dayName[0];
        }),
    };
};

export default groupDataForEachDayInAWeek;
