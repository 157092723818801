import { JSXElementConstructor, ReactElement } from "react";
import { renderToString } from "react-dom/server";
import { MockedFunction } from "vitest";
import { getTextContentInHTMLString } from ".";

const getTextContentOfChildrenPropInGivenSpy = (
    spy: MockedFunction<
        ({ children }: { children: React.ReactNode }) => JSX.Element
    >
) => {
    const childrenProp = spy?.mock?.calls?.[0]?.[0]?.children;

    assert(childrenProp, "children prop is not found in spy");

    if (
        typeof childrenProp === "string" ||
        typeof childrenProp === "number" ||
        typeof childrenProp === "boolean"
    ) {
        return childrenProp.toString();
    }

    const children = renderToString(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        childrenProp as ReactElement<any, string | JSXElementConstructor<any>>
    );

    const currentTextContent = getTextContentInHTMLString(children);

    return currentTextContent;
};

export default getTextContentOfChildrenPropInGivenSpy;
