/* eslint-disable unicorn/prefer-switch */

/* eslint-disable unicorn/no-negated-condition */

/* eslint-disable max-lines */
import { useState } from "react";
import { toast } from "react-toastify";
import { useCurrentUser } from "@/context/currentUser";
import useModalContext from "@/context/modals/useModalContext";
import { useRouter } from "next/router";
import routes from "routes";
import { useSignUpMutation } from "../operations/operations.generated";
import { browserName, browserVersion } from "react-device-detect";


type SelectionProp = {
    firstName: string;
    lastName: string;
    email: string;
    otp: string;
    mobile: string;
    username: string;
    password: string;
    state: string;
    city: string;
    confirmPassword: string;
    emailVerificationSent: boolean;
    mobileVerificationSent: boolean;
    emailVerified: boolean;
    mobileVerified: boolean;
};

type ErrorProp = {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    username: string;
    password: string;
    state: string;
    city: string;
    confirmPassword: string;
};

const useRegisterUser = () => {
    const [errors, setErrors] = useState<ErrorProp>({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        username: "",
        password: "",
        state: "",
        city: "",
        confirmPassword: "",
    });

    const { refetch } = useCurrentUser();

    const { setRegisterOpen } = useModalContext();

    const [mutate] = useSignUpMutation({
        onCompleted(data) {
            if (data?.signUp?.success) {
                refetch();
                localStorage.setItem("isLoggedIn", "true");
                router.push(routes.verifyEmail().href);
                setRegisterOpen(false);
            } else {
                toast.dismiss();
                toast.error(
                    data?.signUp?.message ?? "Oops! Something went wrong."
                );
            }
        },
    });

    const [loading, setLoading] = useState(false);

    const [selection, setSelection] = useState<SelectionProp>({
        firstName: "",
        lastName: "",
        email: "",
        otp: "",
        mobile: "",
        username: "",
        password: "",
        state: "",
        city: "",
        confirmPassword: "",
        emailVerificationSent: false,
        mobileVerificationSent: false,
        emailVerified: false,
        mobileVerified: false,
    });

    const router = useRouter();

    const handleSubmit = async () => {
        setLoading(true);

        const currentErrors = errors;

        try {
            currentErrors.firstName =
                selection.firstName === ""
                    ? "Please enter your first name"
                    : "";
            currentErrors.lastName =
                selection.lastName === "" ? "Please enter your last name" : "";
            currentErrors.email =
                selection.email === "" ? "Please enter your email" : "";
            currentErrors.mobile =
                selection.mobile === "" ? "Please enter your mobile" : "";
            currentErrors.username =
                selection.username === "" ? "Please enter your username" : "";
            currentErrors.password =
                selection.password === "" ? "Please enter your password" : "";
            currentErrors.confirmPassword =
                selection.confirmPassword === ""
                    ? "Please enter your confirm password"
                    : "";
            currentErrors.confirmPassword =
                selection.password !== selection.confirmPassword
                    ? "Password and confirm password do not match"
                    : "";

            currentErrors.state =
                selection.state === "" ? "Please enter the state" : "";
            currentErrors.city =
                selection.city === "" ? "Please enter the city" : "";
            if (
                currentErrors.firstName === "" &&
                currentErrors.lastName === "" &&
                currentErrors.email === "" &&
                currentErrors.mobile === "" &&
                currentErrors.username === "" &&
                currentErrors.password === "" &&
                currentErrors.confirmPassword === "" &&
                currentErrors.state === "" &&
                currentErrors.city === ""
            ) {
                const source = document.cookie
                    .split("; ")
                    .find((row) => row.startsWith("utm_source"))
                    ?.split("=")[1];

                const utmMedium = document.cookie
                    .split("; ")
                    .find((row) => row.startsWith("utm_medium"))
                    ?.split("=")[1];

                const utmCampaign = document.cookie
                    .split("; ")
                    .find((row) => row.startsWith("utm_campaign"))
                    ?.split("=")[1];

                const utmContent = document.cookie
                    .split("; ")
                    .find((row) => row.startsWith("utm_content"))
                    ?.split("=")[1];

                mutate({
                    variables: {
                        firstName: selection.firstName,
                        lastName: selection.lastName,
                        email: selection.email,
                        mobile: selection.mobile,
                        username: selection.username,
                        password: selection.password,
                        state: selection.state,
                        city: selection.city,
                        utmSource: source?.toString() ?? "mockat",
                        utmMedium: utmMedium?.toString() ?? "",
                        utmCampaign: utmCampaign?.toString() ?? "",
                        utmContent: utmContent?.toString() ?? "",
                        deviceType: `${browserName}-${browserVersion}`
                    },
                });
            } else {
                setErrors({ ...currentErrors });
            }
        } catch {
            //
        } finally {
            setLoading(false);
        }
    };

    return {
        errors,
        selection,
        handleSubmit,
        setSelection,
        loading,
    };
};

export default useRegisterUser;

export type { SelectionProp, ErrorProp };