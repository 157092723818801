import React, { useState } from "react";
import FreeContext from "./context";

const FreeProvider = ({ children }: { children: JSX.Element }) => {
    const [freeData, setFreeData] = useState({});

    return (
        <FreeContext.Provider
            value={{
                freeData,
                setFreeData,
            }}
        >
            {children}
        </FreeContext.Provider>
    );
};

export default FreeProvider;
