enum AdminFieldTypes {
    TEXT = "text",
    SLUG = "slug",
    BOOLEAN = "boolean",
    NUMBER = "number",
    DATE = "date",
    TEXTAREA = "textarea",
    SELECT = "select",
    FOREIGN_KEY = "foreign_key",
    MANY_TO_MANY = "many_to_many",
    MANY_TO_MANY_CHECKBOX = "many_to_many_checkbox",
    ONE_TO_MANY = "one_to_many",
    IMAGE = "file",
    DATE_TIME = "date_time",
    CUSTOM_LIST = "custom_list",
}

export default AdminFieldTypes;
