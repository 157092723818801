import moment from "moment";
import POV_GENRE, { POV_DIFFICULTY, POV_STATUS, POV_TIMES } from "./PovGenre";
import ACTIVITY_TYPES_IMAGES from "./activityTypesImages";
import ALL_ARITHMETICS from "./allArithmetics";
import ALL_TABLES from "./allTables";
import DEFAULT_PLANS from "./defaultPlans";
import isProduction from "./isProduction";
import IS_SSR from "./isSsr";
import PROTECTED_ROUTES from "./protectedRoutes";
import SPEED_CONCEPT_CATEGORIES from "./quizCategories";
import QUIZ_DOMAINS, { COMPETE_QUIZ_DOMAINS, LEARN_DOMAINS } from "./quizDomains";
import QUIZ_SUPER_DOMAINS, { COMPETE_SUPER_QUIZ_DOMAINS,PRACTICE_SUPER_DOMAINS } from "./quizSuperDomains";
import QUIZZES from "./quizzes";
import SIDE_NAV_OPTIONS from "./sidebarOptions";
import { SPEED_MATH_CHALLENGE_TIMER, SPEED_QUANT_TIMER, SPEED_VERBAL_TIMER, SPEED_VOCAB_TIMER, SPEED_MATH_PRACTICE_TIMER } from "./speedTimerOptions";
import { SPEED_MATH_TYPE, SPEED_QUANT_TYPE, SPEED_VERBAL_TYPE, SPEED_VOCAB_TYPE } from "./speedTypeOptions";
import STATES from "./states";
import SUPER_DOMAINS from "./superDomains";
import UNPROTECTED_ROUTES from "./unProtectedRoutes";


const LAST_SEVEN_DAYS = moment().subtract(7, "days").toISOString();

export {
    ALL_TABLES,
    ALL_ARITHMETICS,
    LEARN_DOMAINS,
    SIDE_NAV_OPTIONS,
    SPEED_MATH_TYPE,
    SPEED_QUANT_TYPE,
    SPEED_VERBAL_TYPE,
    SPEED_VOCAB_TYPE,
    SPEED_MATH_CHALLENGE_TIMER,
    STATES,
    PROTECTED_ROUTES,
    SPEED_QUANT_TIMER,
    SPEED_VERBAL_TIMER,
    SPEED_VOCAB_TIMER,
    SPEED_MATH_PRACTICE_TIMER,
    IS_SSR,
    PRACTICE_SUPER_DOMAINS,
    SUPER_DOMAINS,
    QUIZ_DOMAINS,
    LAST_SEVEN_DAYS,
    isProduction,
    ACTIVITY_TYPES_IMAGES,
    QUIZ_SUPER_DOMAINS,
    COMPETE_SUPER_QUIZ_DOMAINS,
    COMPETE_QUIZ_DOMAINS,
    QUIZZES,
    SPEED_CONCEPT_CATEGORIES,
    POV_GENRE,
    POV_DIFFICULTY,
    POV_STATUS,
    POV_TIMES,
    UNPROTECTED_ROUTES,
    DEFAULT_PLANS,
};