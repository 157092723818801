import isClient from "./isClient";

const getList = (html: string) => {
    if(!isClient()) return [];
    
    const tempDiv = document.createElement("div");

    tempDiv.innerHTML = html;

    // Extract the list items as an array of strings
    const listItemElements = tempDiv.querySelectorAll("li");

    const liArray = [...listItemElements].map(
        (li) => li?.textContent?.trim() ?? ""
    );

    return liArray ?? [];
};

export default getList;
