import React, { useState } from "react";
import PlannerContext from "./context";

const PlannerProvider = ({ children }: { children: JSX.Element }) => {
    const [plannerIndex, setPlannerIndex] = useState(0);

    const [subTabIndex, setSubTabIndex] = useState(0);

    return (
        <PlannerContext.Provider
            value={{
                plannerIndex,
                setPlannerIndex,
                subTabIndex,
                setSubTabIndex,
            }}
        >
            {children}
        </PlannerContext.Provider>
    );
};

export default PlannerProvider;
