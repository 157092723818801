/* eslint-disable max-lines */
import React, { useState, Fragment, useContext, useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { toast } from "react-toastify";
import { isProduction } from "@/constants";
import { useCurrentUser } from "@/context/currentUser";
import ModalsContext from "@/context/modals";
import { useSendVerificationOtpMutation, useVerifyOtpMutation } from "@/types/generated-types";
import { getImageUrl } from "@/utils";
import { Dialog, Transition } from "@headlessui/react";
// @ts-ignore
import { gtag, install } from "ga-gtag";
import { useRouter } from "next/router";
import routes from "routes";


const MobileVerificationModal = () => {
    const { setMobileVerificationOpen } = useContext(ModalsContext);

    const closeModal = () => {
        // setIsOpen(false);
        setMobileVerificationOpen(false);
    };

    const router = useRouter();

    const [resendOTPLoading, setResendOTPLoading] = useState(false);

    const user = useCurrentUser();

    const [errorText, setErrorText] = useState("");

    const [otpSent, setOtpSent] = useState(false);

    const [mobile, setMobile] = useState(
        (user.mobile?.toString() ?? "").replace("+91", "")
    );

    const [otp, setOtp] = useState("");

    const time = new Date();

    time.setSeconds(time.getSeconds() + 60);

    const { start, seconds, restart } = useTimer({
        autoStart: false,
        expiryTimestamp: time,
        onExpire: () => {
            setOtpSent(true);
            setResendOTPLoading(false);
        },
    });

    const [sendMobileVerification] = useSendVerificationOtpMutation({
        onCompleted(data) {
            toast.dismiss();
            if (data?.sendVerificationOtp?.success) {
                toast.success(data?.sendVerificationOtp?.message);
                setOtpSent(true);
                setResendOTPLoading(true);
                if (seconds <= 0) {
                    const time = new Date();

                    time.setSeconds(time.getSeconds() + 60);

                    restart(time);
                }
            } else {
                toast.error(data?.sendVerificationOtp?.message);
            }
        },
    });

    useEffect(() => {
        if (user?.mobileVerified) {
            setMobileVerificationOpen(false);
        }
    }, [user?.mobileVerified]);

    const handleSendOtp = () => {
        if (mobile.length !== 10 || Number.isNaN(mobile)) {
            toast.dismiss();
            toast.error("Please Enter a valid 10-digit Mobile Number");
        } else {
            const mobileNumber = Number.parseInt(mobile, 10);

            if (mobileNumber < 6_000_000_000 || mobileNumber > 9_999_999_999) {
                toast.dismiss();
                toast.error("Please Enter a valid Mobile Number");
            } else {
                // The mobile number is valid, send the OTP and proceed
                sendMobileVerification({
                    variables: {
                        name: user.username || "user",
                        number: mobile,
                    },
                });
                setResendOTPLoading(true);
                if (seconds <= 0) {
                    const time = new Date();

                    time.setSeconds(time.getSeconds() + 60);
                    restart(time);
                }
            }
        }
    };

    const handleSubmitOtp = async () => {
        if (otp.length !== 6) {
            toast.dismiss();
            toast.error("OTP should be of length 6");
        }
        toast.dismiss();
        toast.loading("Verifying OTP...");

        verifyOtp({
            variables: {
                otp,
                mobile,
            },
        });
    };

    const [verifyOtp] = useVerifyOtpMutation({
        onCompleted(data) {
            if (data?.verifyOtp?.success) {
                if (isProduction) {
                    install("AW-962348483/uHsBCMqGibADEMOL8coD");
                    gtag("event", "conversion", {
                        send_to: "AW-962348483/uHsBCMqGibADEMOL8coD",
                    });

                    install("AW-962348483/5yTYCOzdt4wZEMOL8coD");
                    gtag("event", "conversion", {
                        send_to: "AW-962348483/5yTYCOzdt4wZEMOL8coD",
                    });

                    import("react-facebook-pixel")
                        .then((x) => x.default)
                        .then((ReactPixel) => {
                            ReactPixel.init("724925653096131");
                            ReactPixel.track("CompleteRegistration", {
                                name: user.firstName + " " + user.lastName,
                                username: user.username,
                                mobile,
                                city: user.city,
                                state: user.state,
                                value: 1,
                            });

                            ReactPixel.track("Contact", {
                                name: user.firstName + " " + user.lastName,
                                username: user.username,
                                mobile,
                                city: user.city,
                                state: user.state,
                                value: 2,
                            });
                        });
                }
                toast.dismiss();
                toast.success("Mobile Verified");
                localStorage.getItem("mobile_verified") === "true";
                if (localStorage.getItem("catFlow") === "false") {
                    router.push("/profile");
                    user.refetch();
                    setMobileVerificationOpen(false);

                    return;
                }

                if (localStorage.getItem("catFlow") === "true") {
                    router.push(routes.catShowMarks());
                    user.refetch();
                    setMobileVerificationOpen(false);

                    return;
                }

                router.push(router.pathname);
                setMobileVerificationOpen(false);
            } else {
                toast.dismiss();
                toast.error(data?.verifyOtp?.message);
                // @ts-ignore
                setErrorText(data?.verifyOtp?.message);
            }
        },
    });

    return (
        <>
            <Transition appear show={true} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 overflow-y-auto"
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="mt-12 w-full max-w-lg transform rounded bg-white px-4 py-2 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="mb-4 w-full "
                                    >
                                        <div
                                            className={`flex items-center justify-end`}
                                        >
                                            <p
                                                onClick={() => {
                                                    setMobileVerificationOpen(
                                                        false
                                                    );
                                                    user.handleLogout();
                                                }}
                                                className="my-3 cursor-pointer rounded-md bg-blue-3 px-5 py-1.5 text-lg font-medium text-white hover:underline"
                                            >
                                                Logout
                                            </p>
                                        </div>
                                        <div className="mb-6 flex items-center justify-center">
                                            <img
                                                src={getImageUrl(
                                                    "/images/OTP.svg"
                                                )}
                                                alt="mobile"
                                                width={200}
                                                height={100}
                                            />
                                        </div>
                                        <p className="mb-4 text-center text-2xl font-bold  text-[#5B5B5B]">
                                            OTP Verification
                                        </p>
                                        <p className="mx-auto w-11/12 text-center text-[#5B5B5B]">
                                            We will send you one-time password
                                            to you mobile number
                                        </p>
                                    </Dialog.Title>

                                    <div className="mx-12 flex flex-col">
                                        <input
                                            type="number"
                                            min="6666666666"
                                            max="9999999999"
                                            onKeyPress={(event) => {
                                                // ignore "-", "+" and "e"
                                                if (
                                                    event?.key === "-" ||
                                                    event?.key === "+" ||
                                                    event?.key === "e"
                                                ) {
                                                    event.preventDefault();
                                                }
                                                // if character is not number then ignore except decimal point and
                                                if (
                                                    event?.key !== "." &&
                                                    event?.key &&
                                                    Number.isNaN(+event.key)
                                                ) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            inputMode="numeric"
                                            value={mobile}
                                            onChange={(e) =>
                                                setMobile(e.target.value)
                                            }
                                            name="mobile"
                                            placeholder="Enter Mobile number"
                                            className="my-2 border-b border-[#2743FD] bg-white px-3 py-2"
                                        />
                                        <div className="flex items-center justify-center text-center">
                                            <button
                                                disabled={
                                                    resendOTPLoading ||
                                                    seconds > 60
                                                }
                                                onClick={
                                                    resendOTPLoading
                                                        ? () => {
                                                              //
                                                          }
                                                        : () => {
                                                              handleSendOtp();
                                                              start();
                                                          }
                                                }
                                                className={`my-2 w-fit rounded-[15px] text-xl ${
                                                    otpSent && seconds > 0
                                                        ? "border border-gray-200 bg-gray-100 text-black"
                                                        : "bg-blue-3 bg-opacity-90 text-white"
                                                } px-8 py-2 text-sm font-medium`}
                                            >
                                                {resendOTPLoading
                                                    ? "Resend OTP"
                                                    : otpSent
                                                    ? "Resend OTP"
                                                    : "Get OTP"}
                                            </button>
                                            {/* {otpSent && seconds > 0 && (
                                                    <p className="text-10">
                                                        Resend OTP in {seconds}
                                                        &nbsp; seconds
                                                    </p>
                                                )} */}
                                        </div>

                                        {/* {otpSent && ( */}
                                        <Fragment>
                                            <div className="relative mb-3 flex items-center justify-end">
                                                <input
                                                    onKeyPress={(event) => {
                                                        // ignore "-", "+" and "e"
                                                        if (
                                                            event?.key ===
                                                                "-" ||
                                                            event?.key ===
                                                                "+" ||
                                                            event?.key === "e"
                                                        ) {
                                                            event.preventDefault();
                                                        }
                                                        // if character is not number then ignore except decimal point and
                                                        if (
                                                            event?.key !==
                                                                "." &&
                                                            event?.key &&
                                                            Number.isNaN(
                                                                +event.key
                                                            )
                                                        ) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    inputMode="numeric"
                                                    value={otp}
                                                    maxLength={6}
                                                    type="number"
                                                    onChange={(e) =>
                                                        setOtp(e.target.value)
                                                    }
                                                    placeholder="OTP"
                                                    className="w-full border-b border-[#2743FD] px-4 py-2 pr-10 outline-none"
                                                />
                                            </div>
                                            {errorText && (
                                                <p className="my-1 text-sm text-red-500">
                                                    {errorText}
                                                </p>
                                            )}
                                            <button
                                                disabled={!otp}
                                                className={`mx-auto my-2 w-fit rounded-[15px] bg-primary-1 bg-opacity-90 px-10 py-2 text-xl font-medium text-white  ${
                                                    otp
                                                        ? "bg-blue-3 text-white"
                                                        : "border border-gray-200 bg-gray-100 text-black"
                                                }`}
                                                onClick={() =>
                                                    handleSubmitOtp()
                                                }
                                            >
                                                Verify
                                            </button>
                                        </Fragment>
                                        {/* )} */}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default MobileVerificationModal;