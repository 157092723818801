const STATES = [
    {
        label: "Andhra Pradesh",
        value: "Andhra Pradesh",
    },
    {
        label: "Arunachal Pradesh",
        value: "Arunachal Pradesh",
    },
    {
        label: "Assam",
        value: "Assam",
    },
    {
        label: "Bihar",
        value: "Bihar",
    },
    {
        label: "Chhattisgarh",
        value: "Chhattisgarh",
    },
    {
        label: "Goa",
        value: "Goa",
    },
    {
        label: "Gujarat",
        value: "Gujarat",
    },
    {
        label: "Haryana",
        value: "Haryana",
    },
    {
        label: "Himachal Pradesh",
        value: "Himachal Pradesh",
    },
    {
        label: "Jammu and Kashmir",
        value: "Jammu and Kashmir",
    },
    {
        label: "Jharkhand",
        value: "Jharkhand",
    },
    {
        label: "Karnataka",
        value: "Karnataka",
    },
    {
        label: "Kerala",
        value: "Kerala",
    },
    {
        label: "Ladakh",
        value: "Ladakh",
    },
    {
        label: "Lakshadweep",
        value: "Lakshadweep",
    },
    {
        label: "Madhya Pradesh",
        value: "Madhya Pradesh",
    },
    {
        label: "Maharashtra",
        value: "Maharashtra",
    },
    {
        label: "Manipur",
        value: "Manipur",
    },
    {
        label: "Meghalaya",
        value: "Meghalaya",
    },
    {
        label: "Mizoram",
        value: "Mizoram",
    },
    {
        label: "Nagaland",
        value: "Nagaland",
    },
    {
        label: "Odisha",
        value: "Odisha",
    },
    {
        label: "Punjab",
        value: "Punjab",
    },
    {
        label: "Rajasthan",
        value: "Rajasthan",
    },
    {
        label: "Sikkim",
        value: "Sikkim",
    },
    {
        label: "Tamil Nadu",
        value: "Tamil Nadu",
    },
    {
        label: "Telangana",
        value: "Telangana",
    },
    {
        label: "Tripura",
        value: "Tripura",
    },
    {
        label: "Uttar Pradesh",
        value: "Uttar Pradesh",
    },
    {
        label: "Uttarakhand",
        value: "Uttarakhand",
    },
    {
        label: "West Bengal",
        value: "West Bengal",
    },
];

export default STATES;
