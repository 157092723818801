import React, { useState } from "react";
import CompeteContext from "./context";

const CompeteProvider = ({ children }: { children: JSX.Element }) => {
    const [competeData, setCompeteData] = useState({});

    return (
        <CompeteContext.Provider
            value={{
                competeData,
                setCompeteData,
            }}
        >
            {children}
        </CompeteContext.Provider>
    );
};

export default CompeteProvider;
