import React, { Fragment, useEffect } from "react";
import "react-chat-elements/dist/main.css";
import "react-chat-elements/dist/main.css";
import "react-circular-progressbar/dist/styles.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "@/apollo";
import ShowModals from "@/components/Modals/ShowModals";
import RouteGuard from "@/components/RouteGuard";
import { CatResultProvider } from "@/context/catResult";
import { ChannelThemeProvider } from "@/context/channelTheme";
import { CompeteProvider } from "@/context/compete";
import { DailyProvider } from "@/context/daily";
import { FreeProvider } from "@/context/free";
import { LeaderBoardProvider } from "@/context/leaderBoard";
import { ModalsProvider } from "@/context/modals";
import { PackageSubscriptionProvider } from "@/context/packagesubscription";
import { PlannerProvider } from "@/context/planner";
import { ResultProvider } from "@/context/result";
import { SessionProvider } from "@/context/session";
import { SpeedProvider } from "@/context/speed";
import { TimelineProvider } from "@/context/timeline";
import "@/styles/Home.module.css";
import "@/styles/globals.css";
import { isClient } from "@/utils";
import { ApolloProvider } from "@apollo/client";
import "@webex/widgets/dist/css/webex-widgets.css";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";


const NoSSRCurrentUserProvider = dynamic(
    () => import("../context/currentUser/CurrentUserProvider"),
    { ssr: false }
);

// EMpty commit

class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI

        return { hasError: true };
    }
    componentDidCatch(error: any, errorInfo: any) {
        // You can use your own error logging service here
        console.log({ error, errorInfo });
    }
    render() {
        // Check if the error is thrown
        // @ts-ignore
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <h2>Oops, there is an error!</h2>
                    <button
                        type="button"
                        onClick={() => this.setState({ hasError: false })}
                    >
                        Try again?
                    </button>
                </div>
            );
        }

        // Return children components in case of no error

        // @ts-ignore
        return this.props.children;
    }
}

export default function App({ Component, pageProps }: AppProps) {
    const router = useRouter();

    const source = router?.query?.utm_source as string;
    const utm_medium = router?.query?.utm_medium as string;
    const utm_content = router?.query?.utm_content as string;
    const utm_campaign = router?.query?.utm_campaign as string;

    useEffect(() => {
        if (source && isClient()) {
            document.cookie = `utm_source=${source}; path=/; max-age=31536000`;
        }
    }, [source]);

    useEffect(() => {
        if (utm_medium && isClient()) {
            document.cookie = `utm_medium=${utm_medium}; path=/; max-age=31536000`;
        }
    }, [utm_medium]);

    useEffect(() => {
        if (utm_content && isClient()) {
            document.cookie = `utm_content=${utm_content}; path=/; max-age=31536000`;
        }
    }, [utm_content]);

    useEffect(() => {
        if (utm_campaign && isClient()) {
            document.cookie = `utm_campaign=${utm_campaign}; path=/; max-age=31536000`;
        }
    }, [utm_campaign]);

    return (
        <ErrorBoundary>
            <ApolloProvider client={client}>
                <ModalsProvider>
                    <NoSSRCurrentUserProvider>
                        <PackageSubscriptionProvider>
                            <SessionProvider>
                                <CatResultProvider>
                                    <CompeteProvider>
                                        <TimelineProvider>
                                            <ResultProvider>
                                                <DailyProvider>
                                                    <SpeedProvider>
                                                        <PlannerProvider>
                                                            <FreeProvider>
                                                                <LeaderBoardProvider>
                                                                    <ChannelThemeProvider>
                                                                        <Fragment>
                                                                            <ToastContainer />
                                                                            <RouteGuard>
                                                                                <Fragment>
                                                                                    <Head>
                                                                                        <link
                                                                                            rel="stylesheet"
                                                                                            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                                                                                            integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
                                                                                            crossOrigin="anonymous"
                                                                                        />
                                                                                    </Head>
                                                                                    <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
                                                                                    <Script
                                                                                        src="https://www.googletagmanager.com/gtag/js?id=G-215RWMJWDQ"
                                                                                        strategy="afterInteractive" // Loads the script after the page becomes interactive
                                                                                    />
                                                                                    <Script
                                                                                        id="google-analytics"
                                                                                        strategy="afterInteractive"
                                                                                    >
                                                                                        {`window.dataLayer = window.dataLayer || [];
                                                                                    function gtag(){dataLayer.push(arguments);}
                                                                                    gtag('js', new Date());
                                                                                    gtag('config', 'G-215RWMJWDQ');`}
                                                                                    </Script>
                                                                                    <Script id="facebook-analytics">
                                                                                        {`!function(f,b,e,v,n,t,s)
                                                                                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                                                                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                                                                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                                                                        n.queue=[];t=b.createElement(e);t.async=!0;
                                                                                        t.src=v;s=b.getElementsByTagName(e)[0];
                                                                                        s.parentNode.insertBefore(t,s)}(window,document,'script',
                                                                                        'https://connect.facebook.net/en_US/fbevents.js');
                                                                                        fbq('init', '724925653096131'); 
                                                                                        fbq('track', 'PageView');`}
                                                                                    </Script>
                                                                                    <link
                                                                                        rel="stylesheet"
                                                                                        href="https://code.s4d.io/widget-space/production/main.css"
                                                                                    />
                                                                                    <noscript>
                                                                                        <img
                                                                                            height="1"
                                                                                            width="1"
                                                                                            src="https://www.facebook.com/tr?id=724925653096131&ev=PageView
&noscript=1"
                                                                                        />
                                                                                    </noscript>

                                                                                    <Component
                                                                                        {...pageProps}
                                                                                    />
                                                                                    <ShowModals />
                                                                                </Fragment>
                                                                            </RouteGuard>
                                                                        </Fragment>
                                                                    </ChannelThemeProvider>
                                                                </LeaderBoardProvider>
                                                            </FreeProvider>
                                                        </PlannerProvider>
                                                    </SpeedProvider>
                                                </DailyProvider>
                                            </ResultProvider>
                                        </TimelineProvider>
                                    </CompeteProvider>
                                </CatResultProvider>
                            </SessionProvider>
                        </PackageSubscriptionProvider>
                    </NoSSRCurrentUserProvider>
                </ModalsProvider>
            </ApolloProvider>
        </ErrorBoundary>
    );
}