import React, { useState } from "react";
import LeaderBoardContext from "./context";

const LeaderBoardProvider = ({ children }: { children: JSX.Element }) => {
    const [leaderBoardData, setLeaderBoardData] = useState({
        timer: 0,
        type: "",
    });

    return (
        <LeaderBoardContext.Provider
            value={{
                leaderBoardData,
                setLeaderBoardData,
            }}
        >
            {children}
        </LeaderBoardContext.Provider>
    );
};

export default LeaderBoardProvider;
