import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useCurrentUser } from "../currentUser";
import ModalsContext from "./context";

const ModalsProvider = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn, mobileVerified } = useCurrentUser();

  const [loginOpen, setLoginOpen] = useState(false);

  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const { query } = useRouter();

  const router = useRouter();

  useEffect(() => {
    if (query?.popup === "login") {
      setLoginOpen(true);
    } else if (query?.popup === "register") {
      setRegisterOpen(true);
    } else if (router.asPath.includes("verify-mobile")) {
      setLoginOpen(false);
      if (!mobileVerificationOpen && !mobileVerified) {
        setMobileVerificationOpen(true);
      } else if (mobileVerificationOpen && mobileVerified) {
        setMobileVerificationOpen(false);
      }
    }
  }, [query]);

  const [registerOpen, setRegisterOpen] = useState(false);

  const [mobileVerificationOpen, setMobileVerificationOpen] = useState(false);

  const [selectAvatarOpen, setSelectAvatarOpen] = useState(false);

  const returnFalseIfLoggedIn = (value: boolean) => {
    if (isLoggedIn) {
      return false;
    }

    return value;
  };

  const handleLoginOpen = () => {
    setLoginOpen(true);
  };

  const handleRegisterOpen = () => {
    setRegisterOpen(true);
  };

  return (
    <ModalsContext.Provider
      value={{
        loginOpen: returnFalseIfLoggedIn(loginOpen),
        registerOpen: returnFalseIfLoggedIn(registerOpen),
        mobileVerificationOpen,
        setLoginOpen,
        setRegisterOpen,
        selectAvatarOpen,
        setMobileVerificationOpen,
        setSelectAvatarOpen,
        handleLoginOpen,
        handleRegisterOpen,
        setForgotPasswordOpen,
        forgotPasswordOpen,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};

export default ModalsProvider;
