const POV_GENRE = [
    {
        name: "science-tech",
        id: "Science/Tech",
    },
    {
        name: "social-science",
        id: "Social Science",
    },
    {
        name: "socio-culture",
        id: "Socioculture",
    },
    {
        name: "economics-biz",
        id: "Economics/Biz",
    },
    {
        name: "philosophy",
        id: "Philosophy",
    },

    {
        name: "psychology",
        id: "Psychology",
    },
    {
        name: "ethics",
        id: "Ethics",
    },
    {
        name: "art-&-culture",
        id: "Art/Literature",
    },
    {
        name: "politics",
        id: "Politics",
    },
];

const POV_DIFFICULTY = [
    { id: "Easy", name: "easy" },
    { id: "Medium", name: "medium" },
    { id: "Hard", name: "hard" },
];

const POV_STATUS = [
    { id: "Read", name: "read" },
    { id: "Unread", name: "unread" },
];

const POV_TIMES = [
    {
        id: "< 10 min",
        name: "<10",
    },
    {
        id: "10 - 20 min",
        name: "10-20",
    },
    {
        id: "> 20 min",
        name: ">20",
    },
];

export { POV_DIFFICULTY, POV_STATUS, POV_TIMES };
export default POV_GENRE;
