import React, { useState } from "react";
import ChannelThemeContext from "./context";

const ChannelThemeProvider = ({ children }: { children: JSX.Element }) => {
    const [theme, setTheme] = useState("light");

    const toggleTheme = (theme: string) => {
        setTheme(theme);
    };

    return (
        <ChannelThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ChannelThemeContext.Provider>
    );
};

export default ChannelThemeProvider;
